// export const BACKEND_BASE_URL = 'http://freeditor.wooshelf.com'
export const BACKEND_BASE_URL = 'http://185.210.144.19:81'
export const GOOGLE_FONT_API_KEY = 'AIzaSyBp-oacr2Z61NgRw-sCQ2CIw7UZLm9jNe0'
export const FACEBOOK_AUTH_API_KEY = '545062990408290'
// export const GOOGLE_AUTH_API_KEY = '413622413969-hi8f7p3cc67dur31h2h0b6v2sfm2btbi.apps.googleusercontent.com'
// export const FACEBOOK_AUTH_API_KEY = '240826463892206'
export const GOOGLE_AUTH_API_KEY = '162052169696-00ecqc2rd26q8t0voruloj759r3p5qug.apps.googleusercontent.com'
export const myConstClass = {
    URL: BACKEND_BASE_URL+'/',
};
export const SHORT_CUT_MAP = {
    DELETE_NODE: ["del", "backspace"],
    MOVE_UP: ["up", "w"]
};

export const VIDEO = 'video';
export const AUDIO = 'audio';
export const TEXT = 'text';
export const ADD_FRAME_TO_LAYER = 'addFrameToLayer';
export const UPDATE_FRAME_COORD = 'updateFrameCoord';
export const UPDATE_WAS_DRAGGING_FRAME = 'updateWasDragginFrame';
export const UPDATE_CURRENT_DRAGGED_FRAME = 'updateCurrentDraggedFrame';
export const TOGGLE_MEDIA_VIEW = 'toggleMediaView';
export const TOGGLE_EDITING_TITLE = 'toggleEditingTitle';
export const SET_MEDIA_FILTER = 'setMediaFilter';

export const SOCIAL_MEDIA_FACEBOOK = 'facebook'
export const SOCIAL_MEDIA_INSTAGRAM = 'instagram'
export const FACEBOOK_IMG_SIZE = {
    width: 1200,
    height: 630,
}
export const INSTAGRAM_IMG_SIZE = {
    width: 1080,
    height: 1080
}

export const deleteComponent = {
    title: 'Delete',
    key: 'Delete',
    keyName: 'Backspace',
    windowsKey: 'Delete',
};