import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Script.js";
import { Provider } from 'react-redux';
import store from './store';
import {findAllByRole} from "@testing-library/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
  <BrowserRouter>
    {/*<StrictMode>*/}
      <App />
    {/*</StrictMode>*/}
  </BrowserRouter>
  </Provider>
);
