import React, {useEffect, useState} from "react";
import {FaCloudUploadAlt, FaShapes} from "react-icons/fa";
import {fabric} from "fabric";
import $ from "jquery";
import {BsCircle, BsFillTrashFill, BsLayersHalf, BsStar, BsTrashFill, BsTriangle} from "react-icons/bs";
import {BiCrop, BiPolygon, BiRectangle} from "react-icons/bi";
import {TbAdjustmentsHorizontal, TbOvalVertical, TbSticker} from "react-icons/tb";
import {Button, Card} from "react-bootstrap";
import {FiLayers} from "react-icons/fi";
import {ImCrop, ImTextHeight} from "react-icons/im";
import {useFabricJSEditor} from "fabricjs-react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {RiCrop2Fill} from "react-icons/ri";
import {SiOpenlayers} from "react-icons/si";
import {Slider} from "@mui/material";
import axios from "axios";
import {useSelector} from "react-redux";
import {AiOutlineCloseCircle, AiOutlineDownload} from "react-icons/ai";
import AppBase from "../AppBase";
import {useParams} from "react-router-dom";
import {IMAGE_UPLOAD} from "../../constants/api";
import {ReactComponent as FaceBookIcon} from "../../Assets/facebook.svg"
import {ReactComponent as InstagramIcon} from "../../Assets/instagram.svg"
import ActualSize from "../../Assets/actual-size.png";
// import Modal from "react-modal";
import {
    Avatar,
    Button as AntdButton,
    Card as AntdCard,
    Col,
    Divider,
    Image as AntdImage,
    List,
    Modal,
    Row,
    Space,
    Spin
} from 'antd'
import {displayMessage} from "../../utils/common";
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from "../../constants/dataKey";
import {
    addCircle,
    addEllipse,
    addPolygon,
    addRectAngle,
    addStar,
    addTriangle,
    DEFAULT_STROKE_COLOR,
    DEFAULT_STROKE_WIDTH
} from "../../utils/tools";
import {
    FACEBOOK_IMG_SIZE,
    GOOGLE_FONT_API_KEY,
    INSTAGRAM_IMG_SIZE,
    SOCIAL_MEDIA_FACEBOOK,
    SOCIAL_MEDIA_INSTAGRAM
} from "../../Constants";
import FontPicker from "font-picker-react";
import Login from "../../Pages/Login";

const {Meta} = AntdCard;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Editor = (props) => {
    const {selectedObjects, editor, onReady} = useFabricJSEditor();
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [color, setColor] = useState(null);
    const [toolType, setToolType] = useState('shapes');
    const [toolTypeName, setToolTypeName] = useState('');
    const [isSecondaryPanel, setSecondaryPanel] = useState(false);
    //crop
    const [currentObject2, setCurrentObject] = useState('')
    const [selectedImage, setCurrentImage] = useState('')
    const [selectedCropObject, setSelectionRect] = useState(null)
    const [text, setText] = useState("lorem ipsum");
    const [activeFontFamily, setFontFamily] = useState('sans-sarif');
    const [isEditImage, setEditImage] = useState(false);

    const baseUrl = useSelector(state => state.TextSlice.baseurl);
    const authToken = useSelector(state => state.auth.authToken);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const param = useParams();
    //MODAL PART
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [saveAsLoading, setSaveAsLoading] = React.useState(false);
    const [blurValue, setBlurValue] = React.useState(0);
    const [saveAsModal, setSaveAsModal] = React.useState(false);
    const [socialMediaType, setSocialMediaType] = React.useState('original');
    const [resizeImg, setResizeImg] = React.useState('');
    const [socialMediaSize, setSocialMediaSize] = React.useState(null);
    //login
    const [isShowLogin, setShowLogin] = useState(false);
    const [isSelectMethod, setSelectMethod] = useState("Login");
    const handleShowLogin = () => {
        setShowLogin((isShowLogin) => !isShowLogin);
    };
    const hideLogin = () => {
        if (isLoggedIn) {
            setShowLogin(false);
        }

    }

    const handleMethod = (value) => {
        setSelectMethod((isSelectMethod) => (isSelectMethod = value));
    };
    //end


    useEffect(() => {
        console.log("==========login===isLoggedIntext", isLoggedIn)
        if (!isLoggedIn) {
            handleShowLogin();
            console.log("=============isLoggedIntext", isLoggedIn)
        } else {
            hideLogin()
        }
    }, [authToken, isLoggedIn])

    useEffect(() => {
        applyShortCutKeys()
    },)
    useEffect(() => {
        if (param.id) {
            setIsOpen(true)
        }
    }, [param.id])


    const closeModal = () => {
        setIsOpen(false);
        props.history.push("/medialibrary")
    }

// re-design previews image
    const editOldImage = () => {
        let id = param.id
        setLoading(true)
        let URL = baseUrl + IMAGE_UPLOAD + id
        axios.get(`${URL}/`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + authToken
            },
        }).then((res) => {
            let txt = res.data.image_layout;
            setEditImage(true)
            $("#main-div").show();
            $("#upld-img-create").hide();
            let canvas = window._canvas = new fabric.Canvas('canvas', {
                width: 400,
                height: 600
            });
            resizeCanvas(canvas)
            onReady(canvas);
            canvas.loadFromJSON(`${txt}`, canvas.renderAll.bind(canvas), function (o, object) {
                fabric.log(o, object);
            });
            canvas.renderAll();
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
        setIsOpen(false);
    }


    const resizeCanvas = (canvas) => {
        let winW = $(window).width(),
            winH = $(window).height(),
            canW = winW - 75,
            canH = winH - 100;
        canvas.setWidth(canW);
        canvas.setHeight(canH);
        canvas.renderAll();
    };
    const resizeImageFrame = (canvas, image) => {
        let styleObj = {
            originX: 'left',
            originY: 'top'
        }
        if (image.height === 0 && image.width === 0) {
            styleObj.height = canvas.height - 150
        }
        if (image.height > canvas.height) {
            styleObj.height = canvas.height - 150
        }
        if (image.width > canvas.width) {
            styleObj.width = canvas.width - 150
        }
        return styleObj

    }
    const onChangeImage = (e) => {
        let canvas = new fabric.Canvas('canvas', {
            width: 400,
            height: 500,
        });
        resizeCanvas(canvas)
        let reader = new FileReader();
        reader.onload = function (e) {
            let image = new Image();
            image.src = e.target.result;
            let imgStyle = resizeImageFrame(canvas, image)
            image.onload = function () {
                let img = new fabric.Image(image, imgStyle);
                // img.center()
                img.setCoords();
                canvas.centerObject(img);
                // img.scaleToWidth(canvas.width - 150);
                canvas.backgroundColor = color;
                canvas.add(img).renderAll();
                canvas.setActiveObject(img);
                canvas.sendToBack(img);
                onReady(canvas);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        // setCanvasBox(canvas)
        $("#main-div").show();
        $("#upld-img-create").hide();
    }
    const onChangeTool = (type, name) => {
        setToolType(type)
        setToolTypeName(name)
        setSecondaryPanel(true)
    }
    const polygonTool = () => {

    }

    const starTool = () => {
        // make a star
        let points = starPolygonPoints(5, 50, 25);
        let myStar = new fabric.Polygon(points, {
            stroke: 'black',
            left: 100,
            top: 10,
            strokeWidth: 2,
            fill: '',
            // strokeLineJoin: 'bevil'
        }, false);
        editor.canvas.add(myStar);
    }
    const starPolygonPoints = (spikeCount, outerRadius, innerRadius) => {
        let rot = Math.PI / 2 * 3;
        let cx = outerRadius;
        let cy = outerRadius;
        let sweep = Math.PI / spikeCount;
        let points = [];
        let angle = 0;

        for (let i = 0; i < spikeCount; i++) {
            let x = cx + Math.cos(angle) * outerRadius;
            let y = cy + Math.sin(angle) * outerRadius;
            points.push({x: x, y: y});
            angle += sweep;

            x = cx + Math.cos(angle) * innerRadius;
            y = cy + Math.sin(angle) * innerRadius;
            points.push({x: x, y: y});
            angle += sweep
        }
        return (points);
    }
    const onCrop = () => {
        if (editor.canvas.getActiveObject() == null || editor.canvas.getActiveObject().get('type') != "image") {
            displayMessage(ERROR_MSG_TYPE, 'Please choose image')
            return false
        }
        let currentImage;
        setCurrentObject(editor.canvas.getActiveObject())
        let currentObject = editor.canvas.getActiveObject();
        currentImage = currentObject;
        setCurrentImage(currentObject)
        //for crop arae
        let selectionRect = new fabric.Rect({
            originX: "left",
            originY: "top",
            stroke: DEFAULT_STROKE_COLOR,
            strokeWidth: DEFAULT_STROKE_WIDTH,
            fill: "rgba(0,0,0,0.3)",
            opacity: 1,
            width: currentImage.width / 1.3,
            height: currentImage.height / 1.3,
            hasRotatingPoint: false,
            transparentCorners: false,
            cornerColor: "white",
            cornerStrokeColor: "black",
            borderColor: "black",
            cornerSize: 12,
            padding: 0,
            cornerStyle: "circle",
            borderDashArray: [5, 5],
            borderScaleFactor: 1.3,
        });
        editor.canvas.centerObject(selectionRect);
        editor.canvas.add(selectionRect);
        editor.canvas.setActiveObject(selectionRect);
        editor.canvas.renderAll();
        setSelectionRect(selectionRect)
    }


    const saveCropObject = (e) => {
        let rect = new fabric.Rect({
            left: selectedCropObject.left,
            top: selectedCropObject.top,
            width: selectedCropObject.getScaledWidth(),
            height: selectedCropObject.getScaledHeight(),
            absolutePositioned: true,
        });
        selectedImage.clipPath = rect;
        // remove the mask layer
        editor.canvas.remove(selectedCropObject);
        let cropped = new Image();
        cropped.src = editor.canvas.toDataURL({
            left: rect.left,
            top: rect.top,
            width: rect.width,
            height: rect.height
        });
        cropped.onload = function () {
            let image = new fabric.Image(cropped);
            image.left = rect.left;
            image.top = rect.top;
            image.setCoords();
            editor.canvas.add(image);
            editor.canvas.renderAll();
        };
        editor.canvas.remove(selectedImage);
        setSelectionRect(null)
        e.stopPropagation();
        e.preventDefault();
    }


    const bottomLayer = () => {
        let selectedObject = editor.canvas.getActiveObject();
        editor.canvas.sendToBack(selectedObject);
    }
    const onLayerBelow = () => {
        let selectedObject = editor.canvas.getActiveObject();
        editor.canvas.sendBackwards(selectedObject)
    }
    const topLayer = () => {
        let selectedObject = editor.canvas.getActiveObject();
        editor.canvas.bringToFront(selectedObject)
    }
    const onLayerTop = () => {
        console.log("layer change to bottom")
        let selectedObject = editor.canvas.getActiveObject();
        editor.canvas.bringForward(selectedObject)
    }
    const blurInput = (event) => {
        if (editor.canvas.getActiveObject() == null
            || editor.canvas.getActiveObject().get('type') != "image"
        ) {
            displayMessage(ERROR_MSG_TYPE, 'Please choose image')
            return false
        }
        let val = parseFloat(event.target.value, 10)
        let obj = editor.canvas.getActiveObject();
        let filter = new fabric.Image.filters.Blur({
            blur: val
        });
        while (obj.filters.length > 0) {
            obj.filters.pop();
        }
        obj.filters.push(filter);
        obj.applyFilters();
        editor.canvas.renderAll();
        setBlurValue(val)
        return false
    }

    const uploadMoreImage = (e) => {
        if (!editor) {
            return true
        }
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = function (f) {
            let data = f.target.result;
            fabric.Image.fromURL(data, function (img) {
                let oImg = img.set({
                    transformMatrix: [1, 0, 0, 1, 0, 0]
                });
                // img.scaleToWidth(200);
                img.scaleToHeight(250);
                img.scaleToWidth(500);
                editor.canvas.centerObject(img)
                editor.canvas.add(oImg).renderAll();
                let a = editor.canvas.setActiveObject(oImg);
                let dataURL = editor.canvas.toDataURL({format: 'png', quality: 0.8});
            });
        };
        reader.readAsDataURL(file);
    }

    const changeColorBackGround = () => {
        editor.canvas.backgroundColor = backgroundColor;
        editor.canvas.renderAll();
    }
    const changeColor = () => {
        let cValue = color;
        editor.canvas.getActiveObject()?.set("fill", cValue);
        editor.canvas.renderAll();
    }
    const onAddText = () => {
        const texts = new fabric.IText(text, {
            fontFamily: 'sans-sarif',
            fill: '#333',
            lineHeight: 1.1,
            fontSize: 70,
            editingBorderColor: 'black',
            borderColor: '#ffffff',
            hasRotatingPoint: true
        });
        editor.canvas.add(texts);
        editor.canvas.centerObject(texts);
        editor.canvas.setActiveObject(texts);
        // editor.canvas.selectItemAfterAdded(texts);
        setText("lorem ipsum");
    }
    const changeTextFont = (event) => {
        setFontFamily(event.family);
        let activeObject = editor.canvas.getActiveObject();
        activeObject.fontFamily = event.family
        editor.canvas.renderAll();

    }
    const changeFontSize = (event) => {
        let activeObject = editor.canvas.getActiveObject();
        activeObject.fontSize = event.target.value;
        editor.canvas.renderAll();
    }
    const changeFontColor = (value) => {
        let cValue = value
        editor.canvas.getActiveObject()?.set("fill", cValue);
        editor.canvas.renderAll();
        // let activeObject = editor.canvas.getActiveObject();
        // console.log("=======split", event.target.value.split('#'))
        // activeObject.fill = event.target.value
        // console.log("=======>color", event.target.value, activeObject)
        // editor.canvas.renderAll();
    }
    const onSticker = (e) => {
        const el = e.target;
        fabric.loadSVGFromURL(el.src, function (objects, options) {
            let loadedObjects = fabric.util.groupSVGElements(objects, options);
            loadedObjects.set({
                width: 100,
                height: 100
            });
            editor.canvas.add(loadedObjects);
            editor.canvas.centerObject(loadedObjects);
            editor.canvas.renderAll();

        })
    }

    const save = (e) => {
        let imageBase64 = editor.canvas.toDataURL();
        let layoutOfImage = JSON.stringify(editor.canvas);
        let fd = new FormData;
        fd.append("name", "base64");
        fd.append("image", imageBase64);
        fd.append("image_layout", layoutOfImage);
        fd.append("description", "hello from app");
        setLoading(true);
        return axios.post(`${baseUrl}/apis/image-upload/`, fd, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + localStorage.getItem('auth_token')
            }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === true) {
                displayMessage(SUCCESS_MSG_TYPE, 'Successfully image added')
                localStorage.setItem("editor", JSON.stringify(editor.canvas))
                props.history.push('/medialibrary')
            } else {
                displayMessage(ERROR_MSG_TYPE, 'something went wrong')
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        });
    }

    const remove = () => {
        let activeObjects = editor.canvas.getActiveObjects();
        editor.canvas.discardActiveObject();
        if (activeObjects.length) {
            editor.canvas.remove.apply(editor.canvas, activeObjects);
        }
    }
    const deleteObject = () => {
        editor?.canvas?.remove(editor.canvas.getActiveObject());
    }

    const onChangeSubmenu = (type) => {
        switch (type) {
            case 'circle':
                addCircle(editor);
                break;
            case 'rectAngle':
                addRectAngle(editor);
                break;
            case 'ellipse':
                addEllipse(editor);
                break;
            case 'triangle':
                addTriangle(editor);
                break;
            case 'polygon':
                addPolygon(editor);
                break;
            case 'star':
                addStar(editor);
                break;
            case 'bottomLayer':
                bottomLayer();
                break;
            case 'oneLayerBelow':
                onLayerBelow();
                break;
            case 'topLayer':
                topLayer();
                break;
            case 'oneLayerTop':
                onLayerTop();
                break;
            default:
                return null
        }
    }
    const tools = [
        {
            name: 'Shapes',
            type: 'shapes',
            icon: <FaShapes/>,
            fun: null
        },
        {
            name: 'Add Image',
            type: 'upload',
            icon: <FaCloudUploadAlt/>,
            fun: null
        },

        {
            name: 'Layers',
            type: 'layers',
            icon: <FiLayers/>,
            fun: null
        },
        {
            name: 'Adjust',
            type: 'adjust',
            icon: <TbAdjustmentsHorizontal/>,
            fun: null
        },

        {
            name: 'Text',
            type: 'text',
            icon: <ImTextHeight/>,
            fun: onAddText,
        },

        {
            name: 'Stickers',
            type: 'stickers',
            icon: <TbSticker/>,
            fun: null
        }
    ];

    const shapeSubMenuList = [
        {
            title: 'Circle',
            className: 'circle-shape',
            icon: <BsCircle style={{fontSize: 35}}/>,
            type: 'circle'
        },
        {
            title: 'RectAngle',
            className: 'rect-shape',
            icon: <BiRectangle style={{fontSize: 35}}/>,
            type: 'rectAngle'
        },
        {
            title: 'Ellipse',
            className: 'ellip-shape',
            icon: <TbOvalVertical style={{fontSize: 35}}/>,
            type: 'ellipse'
        },
        {
            title: 'Triangle',
            className: 'triangle-shape',
            icon: <BsTriangle style={{fontSize: 35}}/>,
            type: 'triangle'
        },
        {
            title: 'Polygon',
            className: 'polygon-shape',
            icon: <BiPolygon style={{fontSize: 35}}/>,
            type: 'polygon'
        },
        {
            title: 'Star',
            className: 'star-shape',
            icon: <BsStar style={{fontSize: 35}}/>,
            type: 'star'
        }
    ]
    const deleteSubMenuList = [
        {
            title: 'Delete All',
            className: 'delete-all-shape',
            icon: <BsTrashFill style={{fontSize: 35}}/>,
            type: 'deleteAll'
        },
        {
            title: 'Delete',
            className: 'delete-shape',
            icon: <BsTrashFill style={{fontSize: 35}}/>,
            type: 'delete'
        },
    ]
    const adjustSubMenuList = [
        {
            title: 'Background',
            type: 'background',
            className: '',
            children: [
                {
                    sortTitle: 'BG',
                    title: 'Background',
                    className: 'background-shape',
                    icon: '',
                    type: 'background',
                    value: backgroundColor,
                    setBackGround: setBackgroundColor,
                    setColor: changeColorBackGround,
                },
                {
                    sortTitle: 'TCC',
                    title: 'Target component ',
                    className: 'target-component',
                    icon: '',
                    type: 'target-component',
                    value: color,
                    setBackGround: setColor,
                    setColor: changeColor
                },
            ]
        },
        {
            title: 'Filters',
            type: 'filter',
            className: '',
            children: [
                {
                    sortTitle: 'Blur',
                    title: 'Blur',
                    className: 'blur',
                    icon: '',
                    type: 'blur',
                    onChange: blurInput,

                }
            ]
        },
        {
            title: 'Crop',
            type: 'crop',
            className: '',
        },


    ]
    const cropSubMenuList = [
        {
            title: 'Fixed Crop',
            className: 'fixed-crop-shape',
            icon: <ImCrop style={{fontSize: 35}}/>,
            type: 'fixedCrop',
            id: "startCrop"
        },
        {
            title: 'Free Crop',
            className: 'free-crop-shape',
            icon: <RiCrop2Fill style={{fontSize: 35}}/>,
            type: 'freeCrop',
            id: "crop"
        },
    ]
    const layerSubMenuList = [
        {
            title: 'Top Layer',
            className: 'top-layer',
            icon: <SiOpenlayers style={{fontSize: 35}}/>,
            type: 'topLayer'
        },
        {
            title: 'One Layer Top',
            className: 'one-layer-top',
            icon: <BsLayersHalf style={{fontSize: 35}}/>,
            type: 'oneLayerTop'
        },
        {
            title: 'Bottom Layer',
            className: 'bootom-layer',
            icon: <SiOpenlayers style={{fontSize: 35}}/>,
            type: 'bottomLayer'
        },
        {
            title: 'One Layer Bottom',
            className: 'one-layer-below',
            icon: <BsLayersHalf style={{fontSize: 35}}/>,
            type: 'oneLayerBelow'
        },

    ]

    const stickerSubMenuList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50];

    const saveAs = () => {
        let formData = new FormData;
        let imageBase64 = resizeImg;
        formData.append("name", "base64");
        formData.append("image", imageBase64);
        formData.append("social_media_type", socialMediaType);
        formData.append("height", socialMediaSize.height);
        formData.append("width", socialMediaSize.width);
        setSaveAsLoading(true);
        return axios.post(`${baseUrl}/router/apis/image-export/`, formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + localStorage.getItem('auth_token')
            }
        }).then((res) => {
            let imagePath = res?.data?.image;
            window.open(imagePath)
            displayMessage(SUCCESS_MSG_TYPE, 'Successfully image added')
            setSaveAsLoading(false);
        }).catch((err) => {
            setSaveAsLoading(false);
        });
    }
    const saveAsModalClose = () => {
        setSaveAsModal(false);
        setSocialMediaType('original');
        setSocialMediaSize(null);
    }
    const onSelectType = (type) => {
        setSocialMediaType(type)
        let myGroup = new fabric.Group();
        myGroup.set({originX: 'center', originY: 'center'});

        editor?.canvas?.add(myGroup);
        // put canvas things in new group
        let i = editor?.canvas?.getObjects().length;
        while (i--) {
            let objType = editor?.canvas?.item(i).get('type');
            if (objType === "image" || objType === "text" || objType === "itext" || objType === "rect") {
                let clone = fabric.util.object.clone(editor?.canvas?.item(i));
                myGroup.addWithUpdate(clone).setCoords();
                // remove original lone object
                editor?.canvas?.remove(editor?.canvas?.item(i));
            }
        }
        editor?.canvas?.centerObject(myGroup)
        editor?.canvas?.renderAll();
        // get bounding rect for new group
        let br
        let j = editor?.canvas?.getObjects().length;
        while (j--) {
            let objType = editor?.canvas?.item(j).get('type');
            if (objType === "group") {
                br = editor?.canvas?.item(j).getBoundingRect();
            }
        }

        let obj = {
            left: br.left,
            top: br.top,
            width: br.width,
            height: br.height
        }

        switch (type) {
            case SOCIAL_MEDIA_FACEBOOK:
                obj.height = FACEBOOK_IMG_SIZE.height;
                obj.width = FACEBOOK_IMG_SIZE.width;
                break;
            case SOCIAL_MEDIA_INSTAGRAM:
                obj.height = INSTAGRAM_IMG_SIZE.height;
                obj.width = INSTAGRAM_IMG_SIZE.width;
                break;
        }
        console.log("=========br", obj)
        let resizeImg = editor?.canvas?.toDataURL(br)
        setResizeImg(resizeImg)
        setSocialMediaSize(obj)
    }
    const saveImage = () => {
        setSaveAsModal(true)
        onSelectType('original')
    }
    const applyShortCutKeys = () => {
        console.log("=========apdplyShortCutKeys", props)
        props.shortCutRef.deleteComponent = async function () {
            await deleteObject();
        };
    }
    return (<>
            <Login
                isShowLogin={isShowLogin}
                show={isSelectMethod}
                handleMethod={handleMethod}
                hide={hideLogin}
            />
            <AppBase {...props}>

                <div style={{height: 'calc(100vh - 57px)', width: '100vw', overflow: 'hidden'}}>

                    <Modal
                        centered={true}
                        className={'hold-wait'}
                        width={350}
                        closable={false}
                        maskClosable={false}
                        open={modalIsOpen}
                        onOk={editOldImage}
                        onCancel={closeModal}
                        style={customStyles}
                    >
                        <p>Are you sure want to edit</p>
                    </Modal>
                    <Spin spinning={loading}>
                        {!modalIsOpen &&
                        <div
                            className="App"
                            style={{backgroundColor: 'black'}}
                            // style={{filter: isShowLogin ? "blur(5px)" : ""}}
                        >
                            <div className="row">
                                <div className="col-lg-1 col-md-1 col-12  left-edit-cnt">
                                    <div className="margin-12 left-sidebar-content">
                                        {tools.map((tool) =>
                                            <div className="mb-2 mt-3">
                                                <button
                                                    onClick={() => {
                                                        if (tool.fun) {
                                                            tool.fun()
                                                        }
                                                        onChangeTool(tool.type, tool.name)
                                                    }}
                                                    className="edit-tools-btns">
                                                    {tool.icon}
                                                    <p className={'menu-text'}>{tool.name}</p>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {isSecondaryPanel &&
                                <div className="margin-12 col-lg-2 col-md-2 col-12  left-edit-cnt main-sub-menu">
                                    <div className={'sub-menu'}>
                                        <div className="row ">
                                            <div className={'col-12 sub-menu-heading'}>
                                                <div className={'row'}>
                                                    <div className={'col-8'} style={{textAlign: 'end'}}>
                                                        <h3 className={'sub-menu-text '}>{toolTypeName}</h3>
                                                    </div>
                                                    <div className={'col-4'} style={{textAlign: 'end'}}>
                                                        <AiOutlineCloseCircle style={{fontSize: '24px'}}
                                                                              onClick={() => setSecondaryPanel(false)}/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <RenderSubMenu
                                            tool={toolType}
                                            shapeSubMenuList={shapeSubMenuList}
                                            onChangeSubmenu={onChangeSubmenu}
                                            deleteSubMenuList={deleteSubMenuList}
                                            cropSubMenuList={cropSubMenuList}
                                            adjustSubMenuList={adjustSubMenuList}
                                            layerSubMenuList={layerSubMenuList}
                                            uploadMoreImage={uploadMoreImage}
                                            setColor={setColor}
                                            color={color}
                                            backgroundColor={backgroundColor}
                                            changeColorBackGround={changeColorBackGround}
                                            changeColor={changeColor}
                                            setBackgroundColor={setBackgroundColor}
                                            onAddText={onAddText}
                                            setText={setText}
                                            text={text}
                                            changeTextFont={changeTextFont}
                                            changeFontSize={changeFontSize}
                                            changeFontColor={changeFontColor}
                                            stickerSubMenuList={stickerSubMenuList}
                                            onSticker={onSticker}
                                            blurInput={blurInput}
                                            blurValue={blurValue}
                                            onCrop={onCrop}
                                            editor={editor}
                                            activeFontFamily={activeFontFamily}
                                        />
                                    </div>
                                </div>
                                }
                                <div
                                    className={` margin-12 col-lg-${isSecondaryPanel ? '7' : '9'} col-md-${isSecondaryPanel ? '7' : '9'} col-12 canvas-area-divs`}
                                >
                                    <div className={'row'}>
                                        <div className={'col-12 main-canvas-space '}>
                                            <div className="row" id="upld-img-create">
                                                <div className={'col-8 offset-2'}>
                                                    <h4 className={'upld-img-h1'}>Photo editor & Template designer</h4>
                                                </div>
                                                <div className={'col-8 offset-2'}>
                                                    <div className="upld-img">

                                                        <Button type={'primary'} size={'small'}
                                                                onClick={() => document.getElementById('main-file').click()}
                                                        >Upload Image
                                                        </Button>
                                                        <input type='file' id="main-file" style={{display: 'none'}}
                                                               onChange={onChangeImage}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={'col-12'}>
                                                    <canvas id="canvas">
                                                    </canvas>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'col-12 main-canvas-footer'}>
                                            <div className={'canvas-footer'}>
                                                <div className={'footer-list'}>
                                                    <Space>
                                                        {selectedCropObject ?
                                                            <Button className={'btn-save'} size={'sm'}
                                                                    onClick={saveCropObject}><BiCrop/>save
                                                                crop</Button> : null}
                                                        <Button variant={"secondary"} className={'btn-save'} size={'sm'}
                                                                disabled={!editor?.canvas?.getActiveObject()}
                                                                onClick={deleteObject}><BsFillTrashFill/> Delete</Button>
                                                        <Button className={'btn-save'} size={'sm'}
                                                                disabled={!editor?.canvas}
                                                                onClick={saveImage}><AiOutlineDownload/> Save</Button>
                                                    </Space>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/*</div>*/}
                                    {/*<div className={'row'}>*/}
                                    {/*    <div className={'col-12 canvas-footer'}>*/}
                                    {/*        <Button className={'btn-save'} size={'sm'} onClick={save}><AiOutlineDownload/> Save</Button>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                {/*<div className={'image-save float-end'}>*/}
                                {/*    <Button className={'btn-save'} size={'sm'} onClick={save}><AiOutlineDownload/> Save</Button>*/}
                                {/*</div>*/}
                                <div className={'margin-12 col-lg-2 col-md-3 col-12 '}>
                                    <div className={'adds'} style={{backgroundColor: '#292c31',}}>
                                        <div>
                                            <p>Ads</p>
                                            <Divider/>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        }
                    </Spin>

                    <Modal title={'Save'}
                           width={680}
                           bodyStyle={{padding: 0}}
                           maskClosable={false}
                           open={saveAsModal}
                           onCancel={saveAsModalClose}
                           footer={[
                               <AntdButton key="back" onClick={saveAsModalClose}
                               >Cancel</AntdButton>,
                               <AntdButton loading={saveAsLoading} key="submit" type="primary" onClick={saveAs}
                               >Save As</AntdButton>
                           ]}
                    ><Spin spinning={saveAsLoading} tip={'downloading...'}>
                        <Row>
                            <Col span={9}>
                                <AntdCard
                                    className={'preview-image-container'}
                                    bodyStyle={{padding: 0}}
                                    cover={<AntdImage className={'cover-img'}
                                                      src={resizeImg}/>}
                                >
                                    <Meta title={socialMediaType} description={
                                        socialMediaSize ? `${socialMediaSize.height} x ${socialMediaSize.width}` : null
                                    }/>
                                </AntdCard>


                            </Col>
                            <Col span={14}>
                                <AntdCard bodyStyle={{padding: 0}}
                                          hoverable
                                          className={`mb-2 mt-2 save-as-option ${socialMediaType === 'facebook' ? 'selected-card' : ''}`}
                                          onClick={() => onSelectType('facebook')}>
                                    <List.Item.Meta
                                        avatar={<FaceBookIcon/>}
                                        title={'Facebook'}
                                        description="Small files perfect for photos and sharing"
                                    />
                                </AntdCard>
                                <AntdCard bodyStyle={{padding: 0}}
                                          hoverable
                                          className={`mb-2 mt-2 save-as-option ${socialMediaType === 'instagram' ? 'selected-card' : ''}`}
                                          onClick={() => onSelectType('instagram')}>
                                    <List.Item.Meta
                                        avatar={<InstagramIcon/>}
                                        title={'Instagram'}
                                        description="Small files perfect for photos and sharing"
                                    />
                                </AntdCard>
                                <AntdCard bodyStyle={{padding: 0}}
                                          hoverable
                                          className={`mb-2 mt-2 save-as-option ${socialMediaType === 'original' ? 'selected-card' : ''}`}
                                          onClick={() => onSelectType('original')}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={ActualSize}/>}
                                        title={'Original size'}
                                        description="Small files perfect for photos and sharing"
                                    />
                                </AntdCard>
                            </Col>
                        </Row>
                    </Spin>
                    </Modal>
                </div>
            </AppBase>
        </>

    )
}
export default Editor

function RenderSubMenu(props) {
    switch (props.tool) {
        case 'shapes':
            return (<div className={'row'} style={{marginTop: 35}}>{
                    props.shapeSubMenuList.map((subMenu) =>
                        <div className={'col-6 mb-2'}>
                            <OverlayTrigger placement={'bottom'}
                                            trigger={["hover", "hover"]}
                                            overlay={
                                                <Tooltip>
                                                    {subMenu.title}
                                                </Tooltip>
                                            }>
                                <Card className={'sub-tool'} onClick={() => props.onChangeSubmenu(subMenu.type)}>
                                    <button
                                        className={`edit-btns`}>
                                        {subMenu.icon}
                                    </button>
                                </Card>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            )
        case 'delete':
            return (<div className={'row'} style={{marginTop: 35}}>{
                    props.deleteSubMenuList.map((subMenu) =>
                        <div className={'col-4 mb-2'}>
                            <OverlayTrigger placement={'bottom'}
                                            trigger={["hover", "hover"]}
                                            overlay={
                                                <Tooltip>
                                                    {subMenu.title}
                                                </Tooltip>
                                            }>
                                <Card className={'sub-tool'} onClick={() => props.onChangeSubmenu(subMenu.type)}>
                                    <button
                                        className={`edit-btns ${subMenu.className}`}>
                                        {subMenu.icon}
                                    </button>
                                </Card>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            )
        case 'upload':
            return (<div className={'row'} style={{marginTop: 35}}>
                    <div className={'col-12 mb-2'}>
                        <OverlayTrigger placement={'bottom'}
                                        trigger={["hover", "hover"]}
                                        overlay={
                                            <Tooltip>
                                                file upload
                                            </Tooltip>
                                        }>
                            <>
                                <Button size={'sm'} variant={!props.editor?.canvas ? "secondary" : 'primary'}
                                        disabled={!props.editor?.canvas}
                                        onClick={() => document.getElementById('uploadedImg').click()}
                                >Add Image
                                </Button>
                                <input type='file' id="uploadedImg" style={{display: 'none'}}
                                       onChange={props.uploadMoreImage}/>
                            </>
                        </OverlayTrigger>
                    </div>
                </div>
            )
        case 'adjust':
            return (<div className={'row'} style={{marginTop: 35}}>{
                    props.adjustSubMenuList.map((subMenu) => <>
                            {subMenu.type === 'background' ? <React.Fragment>
                                <div className="bg-clr-change" style={{textAlign: 'start'}}>
                                    <h3 className={'sub-title'}>{subMenu.title}</h3>
                                </div>
                                {subMenu.children.map((item) => <OverlayTrigger placement={'bottom'}
                                                                                trigger={["hover", "hover"]}
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        {item.title}
                                                                                    </Tooltip>
                                                                                }>
                                    <div className={'col-6 mb-2'}>
                                        <label style={{color: '#ffff', fontSize: '14px'}}>{item.sortTitle}</label>
                                        <input type="color" style={{width: '100%'}}
                                               value={item.value}
                                               onChange={(e) => {
                                                   item.setBackGround(e.target.value);
                                                   item.setColor()
                                               }}
                                        />
                                    </div>
                                </OverlayTrigger>)}
                            </React.Fragment> : null}
                            {subMenu.type === 'filter' ?
                                <div className="bg-clr-change" style={{textAlign: 'start'}}>
                                    <h3 className={'sub-title'}>{subMenu.title}</h3>
                                    {/*<Divider className={'custom-divider'}/>*/}
                                    {subMenu.children.map((item) => <>
                                        <h4>Blur Value:</h4> <Slider defaultValue={0} step={0.1}
                                                                     min={0}
                                                                     max={1}
                                                                     onChange={item.onChange}
                                                                     style={{marginBottom: 0}}
                                                                     aria-label="Default"/></>)}
                                </div> : null
                            }
                            {subMenu.type === 'crop' ? <div className="bg-clr-change" style={{textAlign: 'start'}}>
                                <h3 className={'sub-title'}>{subMenu.title}</h3>
                                <div className={'col-6 mb-2'}>
                                    <OverlayTrigger placement={'bottom'}
                                                    trigger={["hover", "hover"]}
                                                    overlay={
                                                        <Tooltip>
                                                            Crop
                                                        </Tooltip>
                                                    }>
                                        <Card className={'crop-sub-tool'} onClick={() => props.onCrop()}>
                                            <button id={'crop-1'}
                                                    className={`edit-btns`}>
                                                <RiCrop2Fill style={{fontSize: 25}}/>
                                            </button>
                                        </Card>
                                    </OverlayTrigger>
                                </div>

                            </div> : null
                            }

                        </>
                    )}
                </div>
            )
        case 'crop':
            return (<div className={'row'} style={{marginTop: 35}}>{
                    props.cropSubMenuList.map((subMenu) =>
                        <div className={'col-6 mb-2'}>
                            <OverlayTrigger placement={'bottom'}
                                            trigger={["hover", "hover"]}
                                            overlay={
                                                <Tooltip>
                                                    {subMenu.title}
                                                </Tooltip>
                                            }>
                                <Card className={'sub-tool'} onClick={() => props.onChangeSubmenu(subMenu.type)}>
                                    <button id={subMenu.id}
                                            className={`edit-btns ${subMenu.className}`}>
                                        {subMenu.icon}
                                    </button>
                                </Card>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            )
        case 'layers':
            return (<div className={'row'} style={{marginTop: 35}}>{
                    props.layerSubMenuList.map((subMenu) =>
                        <div className={'col-6 mb-2'}>
                            <OverlayTrigger placement={'bottom'}
                                            trigger={["hover", "hover"]}
                                            overlay={
                                                <Tooltip>
                                                    {subMenu.title}
                                                </Tooltip>
                                            }>
                                <Card className={'sub-tool'} onClick={() => props.onChangeSubmenu(subMenu.type)}>
                                    <button
                                        className={`edit-btns ${subMenu.className}`}>
                                        {subMenu.icon}
                                    </button>
                                </Card>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            )
        case 'background':
            return (
                <>
                    <div className="bg-clr-change">
                        <h4>Change Background color</h4>
                        <input type="color"
                               value={props.backgroundColor}
                               onChange={(e) => {
                                   props.setBackgroundColor(e.target.value);
                                   props.changeColorBackGround()
                               }}
                        />
                    </div>
                    <div className="bg-clr-change">
                        {/* <ColorPicker width={456} height={228} color={color} onChange={changeColor} hideHSV dark /> */}
                        {/* <input type="color" onChange={changeColor} /> */}
                        <h4>Change target component color</h4>
                        <input type="color"
                               value={props.color} onChange={(e) => {
                            props.setColor(e.target.value);
                            props.changeColor()
                        }}
                        />
                    </div>
                </>
            )
        case 'text':
            return (<div className={'row'} style={{marginTop: 35}}>
                    <div style={{textAlign: 'start'}}>
                        <div className="font-change">
                            <h4>Font family</h4>
                            <FontPicker
                                apiKey={GOOGLE_FONT_API_KEY}
                                activeFontFamily={props.activeFontFamily}
                                onChange={props.changeTextFont}
                            />
                            {/*<select id="text-fonts"*/}
                            {/*        onChange={props.changeTextFont}*/}
                            {/*>*/}
                            {/*    <option value={'sans-sarif'} style={{fontFamily: 'sans-sarif'}} selected>sans-sarif*/}
                            {/*    </option>*/}
                            {/*    <option value={'Combo'} style={{fontFamily: 'Combo'}}>Combo</option>*/}
                            {/*    <option value={'Qwitcher Grypen'} style={{fontFamily: 'Qwitcher Grypen'}}>Qwitcher*/}
                            {/*        Grypen*/}
                            {/*    </option>*/}
                            {/*    <option value={'Montserrat'} style={{fontFamily: 'Montserrat',}}>Montserrat</option>*/}
                            {/*    <option value={'Raleway'} style={{fontFamily: 'Raleway'}}>Raleway</option>*/}
                            {/*    <option value={'Antonio'} style={{fontFamily: 'Antonio'}}>Antonio</option>*/}
                            {/*    <option value={'Lobster'} style={{fontFamily: 'Lobster'}}>Lobster</option>*/}
                            {/*</select>*/}

                            <h4>Font Color</h4>
                            <input type="color" style={{width: '100%'}}
                                   value={props.value}
                                   onChange={(e) => props.changeFontColor(e.target.value)}
                            />
                            <h4>Font Size</h4>
                            <Slider defaultValue={60} step={2} style={{marginBottom: 0}}
                                    min={5} aria-label="Default"
                                    max={500} onChange={props.changeFontSize}/>
                        </div>
                    </div>
                </div>
            )
        case 'filters':
            return (
                <div className="blur-value-change">
                    <h4>Blur Value:</h4>
                    <Slider
                        defaultValue={props.blurValue}
                        value={props.blurValue}
                        // defaultValue={0}
                        step={0.1}
                        min={0}
                        max={1}
                        onChange={props.blurInput}
                        aria-label="Default" valueLabelDisplay="auto"/>
                </div>
            )
        case 'stickers':
            return (
                <div className="row stickers-tools">{
                    props.stickerSubMenuList.map((sticker, index) =>
                        <div className={'sticker-container'}
                             onClick={props.onSticker}
                        >
                            <img src={`http://fabricjs.com/assets/${sticker}.svg`}/>
                        </div>
                    )
                }
                </div>

            )
    }
}