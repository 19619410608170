import React from "react";
import "./App.css";
import 'antd/dist/antd.css';
import {Route, Switch} from 'react-router';
import ReactGA from 'react-ga';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {BrowserRouter as Router} from "react-router-dom";
import configureStore from './redux/store';
import RoutesHome from "./Services/RoutesHome";

const T_I = "UA-244536101-1";
ReactGA.initialize(T_I);

const persistantStore = configureStore();
const {store, persistor} = persistantStore;

const App = () => {

    return (
        <div>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <Switch>
                            <Route
                                path={'/:cat/:subCat'}
                                render={(route) => <RoutesHome {...route} />}
                            />
                            <Route
                                path={'/:cat/'}
                                render={(route) => <RoutesHome {...route} />}
                            />
                            <Route
                                path={'/'}
                                render={(route) => <RoutesHome {...route} />}
                            />
                        </Switch>
                    </Router>
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;

