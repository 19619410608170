import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import Footer from "../Componrnts/HomePage/Footer";
import {useSelector} from 'react-redux'
import axios from "axios";
import {Link} from "react-router-dom";
import ReactGA from 'react-ga';
// import { useEffect } from "react";
import ModalComponent from "./ModalComponent";
import AppBase from "../Componrnts/AppBase";


const MediaLibrary = (props) => {

    //redux text sizes and positions
    const baseUrl = useSelector(state => state.TextSlice.baseurl);
    const token = useSelector(state => state.auth.authToken);
    // console.log(baseUrl)
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [list, setList] = useState([]);


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    useEffect(() => {
        getUserDetails();
        getAllImage();
    }, []);


    function getUserDetails() {

        let config = {
            method: "get",
            url: `${baseUrl}/apis/get-profile-info/`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `token ${token}`,
            },
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                // console.log(response.data.data.first_name);
                setName(response.data.data.first_name);
                setEmail(response.data.data.email);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async function getAllImage() {
        // return false;
        return axios.get(`${baseUrl}/apis/image-upload/`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + token
            }
        }).then((res) => {
            console.log(res)
            if (res.status === 200) {
                // alert("successfully image added")
                setList(res.data)
                console.log("===========id", res.data)

            } else {
                alert("something went wrong")
            }
        })
            .catch((err) => {
                console.log(err);
            });


    }


    return (
        <AppBase {...props}>
            <div
                style={{
                    display: "flex",
                    margin: "20px",
                    justifyContent: "center",
                    fontSize: "30px",
                    fontWeight: "bold",
                }}
            >
                Hi {name}
            </div>

            <div className="medialib">
                <div className="medialibheading">
                    <h1>Your Recent Work</h1>
                    <Link to="/my-all-designs" style={{textDecoration: "none"}}>
                        View All <i class="fas fa-arrow-right"></i>
                    </Link>
                </div>
                <div class="grid-container">


                    {
                        list.map((e, index) => (
                            <>
                                {index < 6 ? (<>

                                    <div class="grid-item" key={index}>
                                        <Card
                                            style={{width: "100%"}}
                                            className="shadow p-3 mb-5 bg-white rounded"
                                        >
                                            <Card.Img variant="top" src={e.image}/>
                                            <Card.Body>
                                                <Card.Title>Your Design Work</Card.Title>
                                                <Link to={"/new-editor/" + e.id}>Edit</Link>

                                                <ModalComponent data={e} index_id={index}/>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                </>) : ''}

                            </>

                        ))
                    }


                </div>
            </div>
            <div className="medialib">
                <div className="medialibheading">
                    <h1>Your Previous Work</h1>
                    <Link to="/my-all-designs" style={{textDecoration: "none"}}>
                        View All <i class="fas fa-arrow-right"></i>
                    </Link>
                </div>
                <div class="grid-container">


                    {
                        list.map((e, index) => (
                            <>
                                {index < 6 ? (<>

                                    <div class="grid-item" key={index}>
                                        <Card
                                            style={{width: "100%"}}
                                            className="shadow p-3 mb-5 bg-white rounded"
                                        >
                                            <Card.Img variant="top" src={e.image}/>
                                            <Card.Body>
                                                <Card.Title>Your Design Work</Card.Title>
                                                <Link to={"/new-editor/" + e.id}>Edit</Link>

                                                <ModalComponent data={e} index_id={index}/>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                </>) : ''}

                            </>

                        ))
                    }


                </div>
            </div>


            {/* <div className="medialib">
        <div className="medialibheading">
          <h1>Your Media Library</h1>
          <a href="" style={{ textDecoration: "none" }}>
            View All <i class="fas fa-arrow-right"></i>
          </a>
        </div>
        <div class="grid-container">
          <div class="grid-item">
            <Card
              style={{ width: "100%" }}
              className="shadow p-3  mb-5 bg-white rounded"
            >
              <Card.Img variant="top" src={Image2} />
              <Card.Body>
                <Card.Title>Your Design Work</Card.Title>
              </Card.Body>
            </Card>
          </div>
          <div class="grid-item">
            <Card
              style={{ width: "100%" }}
              className="shadow p-3 mb-5 bg-white rounded"
            >
              <Card.Img variant="top" src={Image2} />
              <Card.Body>
                <Card.Title>Your Design Work</Card.Title>
              </Card.Body>
            </Card>
          </div>
          <div class="grid-item">
            <Card
              style={{ width: "100%" }}
              className="shadow p-3 mb-5 bg-white rounded"
            >
              <Card.Img variant="top" src={Image2} />
              <Card.Body>
                <Card.Title>Your Design Work</Card.Title>
              </Card.Body>
            </Card>
          </div>
          <div class="grid-item">
            {" "}
            <Card
              style={{ width: "100%" }}
              className="shadow p-3 mb-5 bg-white rounded"
            >
              <Card.Img variant="top" src={Image2} />
              <Card.Body>
                <Card.Title>Your Design Work</Card.Title>
              </Card.Body>
            </Card>
          </div>
          <div class="grid-item">
            <Card
              style={{ width: "100%" }}
              className="shadow p-3 mb-5 bg-white rounded"
            >
              <Card.Img variant="top" src={Image2} />
              <Card.Body>
                <Card.Title>Your Design Work</Card.Title>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div> */}
            <Footer/>
        </AppBase>
    );
};

export default MediaLibrary;
