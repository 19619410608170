import React from 'react'
import { useEffect } from "react";
import Footer from '../Componrnts/HomePage/Footer'
import FrequentlyAskedQuestions from '../Componrnts/HomePage/FrequentlyAskedQuestions'
import AppBase from "../Componrnts/AppBase";
function Faq(props) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <AppBase>
    <FrequentlyAskedQuestions/>
     <Footer/>
    </AppBase>
  )
}

export default Faq