import Header from "../HomePage/Header";
import Login from "../../Pages/Login";
import React, {useState} from "react";

const AppBase = (props) => {
    const [isShowLogin, setShowLogin] = useState(false);
    const [isSelectMethod, setSelectMethod] = useState("Login");
    const handleShowLogin = () => {
        setShowLogin((isShowLogin) => !isShowLogin);
    };
    const hideLogin = () => {
        setShowLogin(false);
    }

    const handleMethod = (value) => {
        setSelectMethod((isSelectMethod) => (isSelectMethod = value));
    };
    return (
        <div>
            <Login
                history={props.history}
                isShowLogin={isShowLogin}
                show={isSelectMethod}
                handleMethod={handleMethod}
                hide={hideLogin}
            />
            <div style={{filter: isShowLogin ? "blur(5px)" : ""}}>
                <Header handleshowlogin={handleShowLogin} handleMethod={handleMethod} {...props}/>
                {props.children}
            </div>
        </div>
    )
}
export default AppBase