import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Layout,
    Modal,
    Popconfirm,
    Row,
    Select,
    Slider,
    Space,
    Spin,
    Tooltip,
    Typography,
    Upload
} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import AppBase from "../AppBase";
import './style.css';
import ReactPlayer from 'react-player';
import VideoEditingTimeline from 'video-editing-timeline-react';
import {
    AudioOutlined,
    CloudDownloadOutlined,
    MergeCellsOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    UndoOutlined,
    UploadOutlined
} from '@ant-design/icons';
import {useSelector} from "react-redux";
import axios from "axios";
import {displayMessage} from "../../utils/common";
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from "../../constants/dataKey";
import {
    VIDEO_COMPONENT,
    VIDEO_CROP,
    VIDEO_DELETE_COMPONENT,
    VIDEO_MERGE,
    VIDEO_ROTATE,
    VIDEO_TEXT,
    VIDEO_WITH_AUDIO
} from "../../constants/api";
import {TbFlipVertical, TbTextResize} from "react-icons/tb";
import {BiCrop, BiRefresh} from "react-icons/bi";
import Login from "../../Pages/Login";

const {Header, Footer, Sider, Content} = Layout;
const {Text} = Typography

const SiderMenu = (props) => {
    const authToken = useSelector(state => state.auth.authToken);
    const baseUrl = useSelector(state => state.TextSlice.baseurl);
    const [selectedToolObject, setSelectedToolObj] = useState(null);
    const [rotate, setRotate] = useState(null);


    const onRotate = React.useCallback(() => {
        const formData = new FormData();
        formData.append('video', props.uploadVideoFile?.component_id);
        formData.append('rotate', rotate);

        props.setLoading(true);
        props.setIsPlaying(false);
        return axios.post(`${baseUrl + VIDEO_ROTATE}`, formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + authToken
            }
        }).then((result) => {
            setRotate(null);
            displayMessage(SUCCESS_MSG_TYPE, 'video edited successfully')
            props.setLoading(false);
            props.setUploadVideoFile({
                component_id: result.data[0].component_id,
                video: result.data[0].output_url
            })
            props.setIsPlaying(true);
        }).catch((err) => {
            props.setLoading(false);
            props.setIsPlaying(false)
        });

    }, [props.uploadVideoFile, rotate]);

    const onFlip = React.useCallback(() => {
        const formData = new FormData();
        formData.append('video', props.uploadVideoFile?.component_id);
        formData.append('rotate', 180);

        props.setLoading(true);
        props.setIsPlaying(false)
        return axios.post(`${baseUrl + VIDEO_ROTATE}`, formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + authToken
            }
        }).then((result) => {
            console.log("=======success", result)
            displayMessage(SUCCESS_MSG_TYPE, 'video edited successfully')
            props.setLoading(false);
            props.setUploadVideoFile({
                component_id: result.data[0].component_id,
                video: result.data[0].output_url
            })
            props.setIsPlaying(true)
        }).catch((err) => {
            props.setLoading(false);
            props.setIsPlaying(false)
        });

    }, [props.uploadVideoFile]);

    const onCrop = React.useCallback(() => {
        const formData = new FormData();
        formData.append('video', props.uploadVideoFile?.component_id);
        formData.append('start_duration', parseInt(props.sliderRange[0].startPoint));
        formData.append('end_duration', parseInt(props.sliderRange[0].endPoint));

        props.setLoading(true);
        props.setIsPlaying(false)
        return axios.post(`${baseUrl + VIDEO_CROP}`, formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + authToken
            }
        }).then((result) => {
            displayMessage(SUCCESS_MSG_TYPE, 'video edited successfully')
            props.setLoading(false);
            props.setUploadVideoFile({
                component_id: result.data[0].component_id,
                video: result.data[0].output_url
            })
            props.setIsPlaying(true)
            console.log("=======success", result)

        }).catch((err) => {
            props.setLoading(false);
            props.setIsPlaying(false)
        });
    }, [props.sliderRange, props.uploadVideoFile]);
    const dummyRequest = useCallback(({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    }, []);

    const uploadMoreFile = React.useCallback((file, object, operation) => {
        if (selectedToolObject) {
            const formData = new FormData();
            formData.append(
                'video1',
                file.originFileObj
            );
            setSelectedToolObj(null)
            // setLoading(true);
            return axios.post(`${baseUrl}/apis/video-add/`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `token ` + authToken
                }
            }).then((result) => {
                switch (operation) {
                    case 'merge':
                        omMergeVideo(result.data[0], object)
                        break
                    case 'add-audio':
                        onAddAudio(result.data[0], object)
                        break
                }
                console.log("=======more", result)
                // setLoading(false);
                displayMessage(SUCCESS_MSG_TYPE, 'Video uploaded Successfully.')

            }).catch((err) => {
                // setLoading(false);
                displayMessage(ERROR_MSG_TYPE, 'Something Wrong.')
                console.log(err);
            });
        }
    }, [selectedToolObject]);


    const omMergeVideo = React.useCallback((value, object) => {
        const formData = new FormData();
        formData.append(
            'video1',
            object?.component_id,
        )
        formData.append('video2', value?.id)
        props.setLoading(true);
        props.setIsPlaying(false)
        return axios.post(`${baseUrl + VIDEO_MERGE}`, formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + authToken
            }
        }).then((result) => {
            console.log("========VIDEO_MERGE", result)
            props.setLoading(false);
            props.setUploadVideoFile({
                component_id: result.data[0].component_id,
                video: result.data[0].output_url
            })
            props.setIsPlaying(true)
            displayMessage(SUCCESS_MSG_TYPE, 'Video uploaded Successfully.')

        }).catch((err) => {
            props.setLoading(false);
            props.setIsPlaying(false)
            displayMessage(ERROR_MSG_TYPE, 'Something Wrong.')
            console.log(err);
        });

    }, [props.sliderRange, props.uploadVideoFile]);

    const onAddAudio = React.useCallback((value, object) => {
        console.log("===omAddAudio2", object);
        const formData = new FormData();
        formData.append('video_id', object?.component_id);
        formData.append('audio_id', value?.id);
        formData.append('starting_duration_audio', parseInt(object.startPoint));
        formData.append('end_duration_audio', parseInt(object.endPoint));
        props.setLoading(true);
        props.setIsPlaying(false)
        return axios.post(`${baseUrl + VIDEO_WITH_AUDIO}`, formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + authToken
            }
        }).then((result) => {
            console.log("========addAudio", result)
            props.setLoading(false);
            props.setUploadVideoFile({
                component_id: result.data[0].component_id,
                video: result.data[0].output_url
            })
            props.setIsPlaying(true)
            displayMessage(SUCCESS_MSG_TYPE, 'Video uploaded Successfully.')

        }).catch((err) => {
            props.setLoading(false);
            props.setIsPlaying(false)
            displayMessage(ERROR_MSG_TYPE, 'Something Wrong.')
            console.log(err);
        });


        console.log("===omAddAudio", value, props.sliderRange, props.uploadVideoFile);
    }, [props.sliderRange, props.uploadVideoFile]);

    return (
        <Sider className={'sider'} theme={'light'} width={120}>
            <Space direction={'vertical'} size={'large'}>

                <Tooltip title="Text" placement={'right'}>
                    <Button disabled={!props.uploadVideoFile} type="text"
                            onClick={() => props.setTextModal(true)}
                            block icon={<TbTextResize style={{fontSize: 32}}/>}/>
                </Tooltip>

                <Tooltip title="Crop" placement={'right'}>
                    <Button disabled={!props.uploadVideoFile} type="text" onClick={onCrop} block
                            icon={<BiCrop style={{fontSize: 32}}/>}/>
                </Tooltip>
                <Tooltip title="Merge" placement={'right'}>
                    <Upload accept={'video/*'}
                            customRequest={dummyRequest}
                            onChange={(info) => {
                                setSelectedToolObj(info.file)
                                uploadMoreFile(info.file, {...props.uploadVideoFile}, 'merge')
                            }}
                    >
                        <Button disabled={!props.uploadVideoFile} type="text" block
                                icon={<MergeCellsOutlined style={{fontSize: 32}}/>}/>
                    </Upload>
                </Tooltip>
                <Tooltip title="Audio" placement={'right'}>
                    <Upload accept={'audio/*'}
                            customRequest={dummyRequest}
                            onChange={(info) => {
                                setSelectedToolObj(info.file)
                                uploadMoreFile(info.file, {...props.sliderRange[0], ...props.uploadVideoFile}, 'add-audio')
                            }}
                    >
                        <Button disabled={!props.uploadVideoFile} type="text" block
                                icon={<AudioOutlined style={{fontSize: 32}}/>}/>
                    </Upload>
                </Tooltip>
                <Tooltip title="Rotate" placement={'right'}>
                    <Popconfirm className={'angle-slider'} placement="left"
                                title={
                                    <Slider min={0} max={360} step={45}
                                            onChange={(value) => setRotate(value)}/>
                                }
                                onConfirm={onRotate}
                                icon={null}
                                onCancel={() => setRotate(null)}
                                okText="Yes"
                                cancelText="No">
                        <Button disabled={!props.uploadVideoFile} type="text" block
                                icon={<BiRefresh style={{fontSize: 32}}/>}/>
                    </Popconfirm>
                </Tooltip>
                <Tooltip title="Flip" placement={'right'}>
                    <Button disabled={!props.uploadVideoFile} type="text" onClick={onFlip} block
                            icon={<TbFlipVertical style={{fontSize: 32}}/>}/>
                </Tooltip>
            </Space>
        </Sider>
    )
}
const VideoEditor = () => {
    const playerRef = React.useRef();
    const previewPlayerRef = React.useRef();
    // const formRef = React.useRef();
    const timeLineRef = React.useRef();
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(0)
    const [played, setPlayed] = useState(0)
    const [sliderRange, setSliderRange] = useState([])
    const authToken = useSelector(state => state.auth.authToken);
    const baseUrl = useSelector(state => state.TextSlice.baseurl);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const [videoFileList, setVideoFileList] = useState([]);
    const [uploadVideoFile, setUploadVideoFile] = useState(null);
    const [originalVideoFile, setOriginalVideoFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [videoComponent, setVideoComponent] = useState([]);
    const [previewVideoPlay, setPreviewVideoPlay] = useState(false);
    const [textModal, setTextModal] = useState(false);
    const [inputText, setInputText] = useState('');
    const [dummyState, setDummyState] = useState('');


    //login
    const [isShowLogin, setShowLogin] = useState(false);
    const [isSelectMethod, setSelectMethod] = useState("Login");
    const handleShowLogin = () => {
        setShowLogin((isShowLogin) => !isShowLogin);
    };
    const hideLogin = () => {
        if (isLoggedIn) {
            setShowLogin(false);
        }

    }

    const handleMethod = (value) => {
        setSelectMethod((isSelectMethod) => (isSelectMethod = value));
    };
    //end


    useEffect(() => {
        console.log("==========login===isLoggedIntext", isLoggedIn)
        if (!isLoggedIn) {
            handleShowLogin();
            console.log("=============isLoggedIntext", isLoggedIn)
        } else {
            hideLogin()
        }
    }, [authToken, isLoggedIn])


    const measuredRef = useCallback(node => {
        if (node !== null) {
            const {colour, fontsize} = node.getFieldsValue();
            const inputTextElm = document.getElementById('inputText');
            inputTextElm.style.color = colour
            inputTextElm.style.fontSize = `${fontsize}px`
            inputTextElm.style.color = colour
        }
    }, []);

    const handleDuration = (duration) => {
        setDuration(duration)
    }
    useEffect(() => {
        if (uploadVideoFile) {
            loadVideoComponent(originalVideoFile.id);
        }

    }, [uploadVideoFile])

    const config = {
        canvasWidth: duration * 10,
        canvasHeight: 25,
        minimumScale: 10,
        minimumScaleTime: 1,
    };
    // const onReady = React.useCallback(() => {
    //     const timeToStart = (7 * 60) + 12.6;
    //     playerRef.current.seekTo(timeToStart, 'seconds');
    // }, [playerRef.current]);
    const onChangeCropArea = (value) => {
        let payload = {
            startPoint: value[0] / 10,
            endPoint: value[1] / 10,
        }

        setSliderRange([payload])
        playerRef.current.seekTo(value[0] / 10);
        setIsPlaying(true);
    };
    const dummyRequest = useCallback(({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    }, []);

    const props = {
        name: 'file',
        headers: {
            "Content-Type": "application/json",
            'Authorization': `token ` + authToken
        },
        accept: 'video/*',
        customRequest: dummyRequest,
        beforeUpload: (file) => {
        },
        onChange(info) {
            uploadVideo(info.file)
            let filtered = info.fileList.filter((file) => !!file.status);
            filtered = filtered.slice(-1);
            if (info.file) {
                setVideoFileList(filtered);
            } else {
                setVideoFileList(filtered);
            }
        },
    };
    const uploadVideo = React.useCallback(() => {
        if (videoFileList.length) {
            const formData = new FormData();
            formData.append(
                'video1',
                videoFileList.length ? videoFileList[0].originFileObj : null
            );
            setLoading(true);
            return axios.post(`${baseUrl}/apis/video-add/`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `token ` + authToken
                }
            }).then((result) => {
                setUploadVideoFile(result.data[0])
                setOriginalVideoFile(result.data[0])
                setLoading(false);
                displayMessage(SUCCESS_MSG_TYPE, 'Video uploaded Successfully.')

            }).catch((err) => {
                setLoading(false);
                displayMessage(ERROR_MSG_TYPE, 'Something Wrong.')
                console.log(err);
            });
        }

    }, [JSON.stringify(videoFileList)])

    const loadVideoComponent = React.useCallback((videoId) => {
        const formData = new FormData();
        formData.append('video_id', videoId)
        return axios.post(`${baseUrl + VIDEO_COMPONENT}`, formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + authToken
            }
        }).then((result) => {
            setVideoComponent(result.data.slice(-1));
        }).catch((err) => {

        });
    }, [])


    const addText = (formValue) => {
        console.log("=========v", formValue)
        const formData = new FormData();
        formData.append('video', uploadVideoFile?.component_id)
        formData.append('text', formValue.text)
        formData.append('colour', formValue.colour)
        formData.append('fontsize', formValue.fontsize)
        formData.append('position', formValue.position)
        formData.append('set_duration', formValue.set_duration)

        setLoading(true);
        setIsPlaying(false)
        return axios.post(`${baseUrl + VIDEO_TEXT}`, formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + authToken
            }
        }).then((result) => {
            setTextModal(false)
            displayMessage(SUCCESS_MSG_TYPE, 'Text Added successfully')
            setLoading(false);
            setUploadVideoFile({
                component_id: result.data[0].component_id,
                video: result.data[0].output_url
            })
            setIsPlaying(true)
        }).catch((err) => {
            setLoading(false);
            setIsPlaying(false)
            setTextModal(false)

        });
    }

    const undoComponent = (componentId) => {
        const formData = new FormData();
        formData.append('video_id', originalVideoFile.id)
        setLoading(true);
        setIsPlaying(false)
        return axios.post(`${baseUrl + VIDEO_DELETE_COMPONENT}`, formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `token ` + authToken
            }
        }).then((result) => {
            displayMessage(SUCCESS_MSG_TYPE, 'Undo successfully')
            setLoading(false);
            if (result.data) {
                setUploadVideoFile({
                    component_id: result.data.slice(-1)[0].component_id2,
                    video: result.data.slice(-1)[0].video_url
                })
            } else {
                setUploadVideoFile(null)
            }

            setIsPlaying(true)
        }).catch((err) => {
            setLoading(false);
            setIsPlaying(false)

        });
    }
    return (<>
            <Login
                isShowLogin={isShowLogin}
                show={isSelectMethod}
                handleMethod={handleMethod}
                hide={hideLogin}
            />
            <AppBase>

                <Layout style={{background: '#ffff'}}>
                    <Header className={'video-editor-header'}>
                        {/*<Tooltip title="Text" placement={'right'}>*/}
                        {/*    <Button type="text" style={{textAlign: 'left'}} block icon={<TextIcon/>}/>*/}
                        {/*</Tooltip>*/}
                    </Header>
                    <Layout className={'video-editor-container'}>
                        <SiderMenu sliderRange={sliderRange} uploadVideoFile={uploadVideoFile}
                                   setUploadVideoFile={setUploadVideoFile}
                                   setLoading={setLoading}
                                   setIsPlaying={setIsPlaying}
                                   setTextModal={setTextModal}
                                   setVideoFileList={setVideoFileList}
                                   originalVideoFile={originalVideoFile}/>
                        <Content>
                            <Spin spinning={loading} tip={'processing...'}>

                                {uploadVideoFile ?
                                    <Row>
                                        <Col span={16} className={'video-container'}>
                                            {console.log("=========hello", uploadVideoFile)}
                                            <ReactPlayer
                                                ref={playerRef}
                                                url={uploadVideoFile.video}
                                                width='100%'
                                                controls={false}
                                                height='70vh'
                                                playing={isPlaying}
                                                onProgress={(progress) => {
                                                    setPlayed(progress.playedSeconds);
                                                }}
                                                onDuration={handleDuration}
                                            />
                                            <Row align={'middle'} className={'video-timeline-container'}>
                                                <Col span={2} style={{textAlign: 'center'}}>
                                                    {console.log("here========", playerRef)}
                                                    {playerRef && playerRef.current?.player?.isPlaying ?
                                                        <PauseCircleOutlined style={{fontSize: 40, color: '#00a3ff'}}
                                                                             onClick={() => setIsPlaying(!isPlaying)}/> :
                                                        <PlayCircleOutlined style={{fontSize: 40, color: '#00a3ff'}}
                                                                            onClick={() => setIsPlaying(!isPlaying)}/>
                                                    }
                                                </Col>
                                                <Col span={22}>
                                                    <div style={{overflowX: 'scroll'}}>

                                                        <div className="vl"
                                                             style={{
                                                                 overflowX: 'scroll',
                                                                 transform: `translateX(${parseInt(played * 10)}px)`
                                                             }}
                                                        />
                                                        <Slider range min={0} max={duration * 10}
                                                                style={{
                                                                    width: duration * 10
                                                                }}
                                                                defaultValue={[0, 150]}
                                                                onChange={onChangeCropArea}/>

                                                        <VideoEditingTimeline ref={timeLineRef} config={config}/>
                                                        <br/>
                                                        <Text
                                                            className={'text-black'}>Duration:{parseInt(duration)} play: {parseInt(played)}sec</Text>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={5} style={{padding: 10}}>
                                            {console.log("======component", videoComponent)}
                                            {videoComponent.length ? videoComponent.map((component) => <Card
                                                    className={'video-component'}
                                                    headStyle={{
                                                        padding: 8
                                                    }}
                                                    bodyStyle={{
                                                        padding: 0
                                                    }}
                                                    title={'Preview and Play'}
                                                    extra={[<Space>
                                                        <UndoOutlined onClick={() => undoComponent(component.video_id)}/>
                                                        <a href={component.video_url}
                                                           target="_blank"
                                                           download><CloudDownloadOutlined/></a>
                                                    </Space>]
                                                    }>
                                                    <ReactPlayer
                                                        ref={previewPlayerRef}
                                                        playing={previewVideoPlay}
                                                        style={{background: '#000000'}}
                                                        url={component.video_url}
                                                        width={'100%'}
                                                        height={'206px'}
                                                        controls={true}

                                                    />
                                                </Card>
                                            ) : null}


                                        </Col>
                                        <Col span={3}>
                                            <div className={'video-ads'}
                                                 style={{height: '100vh', backgroundColor: '#292c31',}}>
                                                <div>
                                                    <p>Ads</p>
                                                    <Divider/>
                                                </div>

                                            </div>
                                        </Col>

                                    </Row> : <Row align={'middle'} style={{
                                        background: 'black', color: 'white',
                                        height: `100vh`
                                    }}
                                    >
                                        <Col span={21} style={{textAlign: 'center'}}>
                                            <h4 className={'upld-img-h1'}>Video editor & Template designer</h4>
                                            <Upload {...props}>
                                                <Button size={'large'} type={'primary'} icon={<UploadOutlined/>}>Click
                                                    to
                                                    Upload</Button>
                                            </Upload>
                                        </Col>
                                        <Col span={3}>
                                            <div className={'video-ads'}
                                                 style={{height: '100vh', backgroundColor: '#292c31',}}>
                                                <div>
                                                    <p>Ads</p>
                                                    <Divider/>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                }
                                <Modal title="Add Text"
                                       footer={null}
                                       open={textModal} onOk={() => setTextModal(false)}
                                       onCancel={() => setTextModal(false)}>
                                    <Form
                                        labelCol={{span: 4}}
                                        wrapperCol={{span: 20}}
                                        onFinish={addText}
                                        ref={measuredRef}
                                    >
                                        <Form.Item label={'Preview'}>
                                            <Text id={'inputText'}>{inputText}</Text>
                                        </Form.Item>

                                        <Form.Item name={'text'} label={'Text'}
                                                   rules={[{required: true, message: 'Text Field required!'}]}>
                                            <Input placeholder={'Text'} onChange={(e) => setInputText(e.target.value)}/>
                                        </Form.Item>
                                        <Form.Item name={'fontsize'} label={'Font Size'}
                                                   rules={[{required: true, message: 'Font Size Field required!'}]}>
                                            <InputNumber
                                                style={{width: '100%'}}
                                                onChange={setDummyState}
                                                placeholder={'Font Size'}/>
                                        </Form.Item>
                                        <Form.Item name={'colour'} label={'Color'}
                                                   rules={[{required: true, message: 'Color Field required!'}]}>
                                            <Input
                                                onChange={setDummyState}
                                                style={{width: '70px'}}
                                                type={'color'} placeholder={'Color'}/>
                                        </Form.Item>
                                        <Form.Item name={'set_duration'} label={'Duration'}
                                                   rules={[{required: true, message: 'Duration Field required!'}]}>
                                            <InputNumber
                                                style={{width: '100%'}}
                                                onChange={setDummyState} placeholder={'Duration'}/>
                                        </Form.Item>
                                        <Form.Item name={'position'} label={'Position'}
                                                   rules={[{required: true, message: 'Position Field required!'}]}>
                                            <Select
                                                onChange={setDummyState}
                                                options={[
                                                    {
                                                        value: 'center',
                                                        label: 'Center',
                                                    },
                                                    {
                                                        value: 'top',
                                                        label: 'Top',
                                                    },
                                                    {
                                                        value: 'bottom',
                                                        label: 'Bottom',
                                                    },
                                                    {
                                                        value: 'left',
                                                        label: 'Left',
                                                    },
                                                    {
                                                        value: 'right',
                                                        label: 'Right',
                                                    },

                                                ]}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            wrapperCol={{offset: 4, span: 20}}
                                        >
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </Spin>
                        </Content>

                    </Layout>
                </Layout>

            </AppBase>
        </>

    )
}
export default VideoEditor

