import React, { useEffect, useState } from "react";
import { fabric } from "fabric"; // this also installed on your project
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import './NewEditor.css';
import $ from 'jquery';
import "react-color-palette/lib/css/styles.css";
import Modal from 'react-modal';
import { useParams } from "react-router";
import { useDispatch, useSelector } from 'react-redux'
import axios from "axios";
import { FaShapes } from 'react-icons/fa';
import { BiCrop } from 'react-icons/bi';
import { ImCrop } from 'react-icons/im';
import { RiCrop2Fill } from 'react-icons/ri';
import { BsFillTrashFill } from 'react-icons/bs';
import { FiLayers } from 'react-icons/fi';
import { BsBack, BsTrashFill } from 'react-icons/bs';
import { ImTextHeight } from 'react-icons/im';
import { TbSticker } from 'react-icons/tb';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { AiOutlineDownload } from 'react-icons/ai';
import { BsStar, BsTriangle } from 'react-icons/bs';
import { BiPolygon } from 'react-icons/bi';
import { BsLayersHalf } from 'react-icons/bs';
import { SiOpenlayers } from 'react-icons/si';
import { FaFilter } from 'react-icons/fa';
import AppBase from "../AppBase";





const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};






function NewEditorEdit(props) {


  const [text, setText] = useState("                  ");
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  // const [color, setColor] = useColor("hex", "#121212");
  const [color, setColor] = useState(null);
  const [font, setFont] = useState('Calibri');


  const [text2, setText2] = useState("");
  const [editText, setEditText] = useState("abc");


  const baseUrl = useSelector(state => state.TextSlice.baseurl);
  const param = useParams();
  // useEffect(()=>{
  //     var canvas = new fabric.Canvas('canvas');
  //     canvas.backgroundColor = color;
  //     canvas.renderAll();

  //     onReady(canvas);
  //   },[])



  //MODAL PART
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);

  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    // navigate("/medialibrary")
  }



  // function drawFun() {
  //     //for render json to template***********************
  //     // Do some initializing stuff
  //     fabric.Object.prototype.set({
  //         transparentCorners: false,
  //         cornerColor: 'rgba(102,153,255,0.5)',
  //         cornerSize: 12,
  //         padding: 5
  //     });


  //     var json=`{
  //         "version": "5.2.4",
  //         "objects": [
  //           {
  //             "type": "i-text",
  //             "version": "5.2.4",
  //             "originX": "left",
  //             "originY": "top",
  //             "left": 528,
  //             "top": 64,
  //             "width": 64.49,
  //             "height": 45.2,
  //             "fill": "#000000",
  //             "stroke": null,
  //             "strokeWidth": 1,
  //             "strokeDashArray": null,
  //             "strokeLineCap": "butt",
  //             "strokeDashOffset": 0,
  //             "strokeLineJoin": "miter",
  //             "strokeUniform": false,
  //             "strokeMiterLimit": 4,
  //             "scaleX": 0.5,
  //             "scaleY": 0.5,
  //             "angle": 0,
  //             "flipX": false,
  //             "flipY": false,
  //             "opacity": 1,
  //             "shadow": null,
  //             "visible": true,
  //             "backgroundColor": "",
  //             "fillRule": "nonzero",
  //             "paintFirst": "fill",
  //             "globalCompositeOperation": "source-over",
  //             "skewX": 0,
  //             "skewY": 0,
  //             "fontFamily": "helvetica",
  //             "fontWeight": "",
  //             "fontSize": 40,
  //             "text": "jeet",
  //             "underline": false,
  //             "overline": false,
  //             "linethrough": false,
  //             "textAlign": "left",
  //             "fontStyle": "normal",
  //             "lineHeight": 1.16,
  //             "textBackgroundColor": "",
  //             "charSpacing": 0,
  //             "styles": [],
  //             "direction": "ltr",
  //             "path": null,
  //             "pathStartOffset": 0,
  //             "pathSide": "left",
  //             "pathAlign": "baseline"
  //           },
  //           {
  //             "type": "i-text",
  //             "version": "5.2.4",
  //             "originX": "left",
  //             "originY": "top",
  //             "left": 527,
  //             "top": 98,
  //             "width": 73.34,
  //             "height": 45.2,
  //             "fill": "#000000",
  //             "stroke": null,
  //             "strokeWidth": 1,
  //             "strokeDashArray": null,
  //             "strokeLineCap": "butt",
  //             "strokeDashOffset": 0,
  //             "strokeLineJoin": "miter",
  //             "strokeUniform": false,
  //             "strokeMiterLimit": 4,
  //             "scaleX": 0.5,
  //             "scaleY": 0.5,
  //             "angle": 0,
  //             "flipX": false,
  //             "flipY": false,
  //             "opacity": 1,
  //             "shadow": null,
  //             "visible": true,
  //             "backgroundColor": "",
  //             "fillRule": "nonzero",
  //             "paintFirst": "fill",
  //             "globalCompositeOperation": "source-over",
  //             "skewX": 0,
  //             "skewY": 0,
  //             "fontFamily": "helvetica",
  //             "fontWeight": "",
  //             "fontSize": 40,
  //             "text": "arijit",
  //             "underline": false,
  //             "overline": false,
  //             "linethrough": false,
  //             "textAlign": "left",
  //             "fontStyle": "normal",
  //             "lineHeight": 1.16,
  //             "textBackgroundColor": "",
  //             "charSpacing": 0,
  //             "styles": [],
  //             "direction": "ltr",
  //             "path": null,
  //             "pathStartOffset": 0,
  //             "pathSide": "left",
  //             "pathAlign": "baseline"
  //           }
  //         ]
  //       }`;


  //     // initialize fabric canvas and assign to global windows object for debug
  //     var canvas = window._canvas = new fabric.Canvas('canvas');

  //     // var json = JSON.stringify(localStorage.getItem('editor'))

  //     canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), function (o, object) {


  //         fabric.log(o, object);
  //         console.log(editor)
  //     });

  //     canvas.backgroundColor = color;
  //     // canvas.renderAll();

  //     onReady(canvas);
  //     console.log("edit page")
  // }





  function imgChng2() { }






  function abc() {
    // console.log(localStorage.getItem('editor'))
    console.log(param.id)
    setIsOpen(false);
    $("#main-div").show();
    // $("#jeet").hide();

    //api call to get image details
    return axios.get(`${baseUrl}/apis/image-upload/${param.id}/`, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `token ` + localStorage.getItem('auth_token')
      }
    }).then((res) => {
      console.log(res)
      if (res.status === 200) {
        var txt = res.data.image_layout;
        console.log(txt)
        var canvas = window._canvas = new fabric.Canvas('canvas');


        onReady(canvas);
        // var json =`${txt}`
        canvas.loadFromJSON(`${txt}`, canvas.renderAll.bind(canvas), function (o, object) {


          fabric.log(o, object);
          console.log(editor)
        });

      } else {
        alert("something went wrong")
      }
    })
      .catch((err) => {
        console.log(err);
      });



    // var txt = localStorage.getItem('editor');
    // console.log(txt)
    // var canvas = window._canvas = new fabric.Canvas('canvas');


    // onReady(canvas);
    // // var json =`${txt}`
    // canvas.loadFromJSON(`${txt}`, canvas.renderAll.bind(canvas), function (o, object) {


    //     fabric.log(o, object);
    //     console.log(editor)
    // });
    // $("#main-div").show();
    // $("#upld-img").hide();
  }












  const onAddCircle = () => {
    editor.addCircle();
  };





  const onAddRectangle = () => {
    editor.addRectangle();
  };



  function onAddTriangle() {
    var tri = new fabric.Triangle({
      top: 10,
      left: 200,
      width: 200,
      height: 100,
      fill: '',
      stroke: 'black',
      strokeWidth: 2
    });

    editor.canvas.add(tri);
  }



  function onAddEcipls() {
    var eli = new fabric.Ellipse({
      top: 150,
      left: 10,
      rx: 75,
      ry: 50,
      fill: '',
      stroke: 'black',
      strokeWidth: 4
    });
    editor.canvas.add(eli);
  }




  function poly() {
    var trapezoid = [{ x: -100, y: -50 }, { x: 100, y: -50 }, { x: 150, y: 50 }, { x: -150, y: 50 }];
    var emerald = [{ x: 850, y: 75 },
    { x: 958, y: 137.5 },
    { x: 958, y: 262.5 },
    { x: 850, y: 325 },
    { x: 742, y: 262.5 },
    { x: 742, y: 137.5 },
    ];
    var star4 = [
      { x: 0, y: 0 },
      { x: 100, y: 50 },
      { x: 200, y: 0 },
      { x: 150, y: 100 },
      { x: 200, y: 200 },
      { x: 100, y: 150 },
      { x: 0, y: 200 },
      { x: 50, y: 100 },
      { x: 0, y: 0 }
    ];
    var star5 = [{ x: 350, y: 75 },
    { x: 380, y: 160 },
    { x: 470, y: 160 },
    { x: 400, y: 215 },
    { x: 423, y: 301 },
    { x: 350, y: 250 },
    { x: 277, y: 301 },
    { x: 303, y: 215 },
    { x: 231, y: 161 },
    { x: 321, y: 161 },];
    var shape = new Array(trapezoid, emerald, star4, star5);

    var polyg = new fabric.Polygon(shape[1], {
      top: 180,
      left: 200,
      fill: '',
      stroke: 'black',
      strokeWidth: 2
    });

    editor.canvas.add(polyg);
  }





  function star() {
    // make a star
    var points = starPolygonPoints(5, 50, 25);
    var myStar = new fabric.Polygon(points, {
      stroke: 'black',
      left: 100,
      top: 10,
      strokeWidth: 2,
      fill: '',
      // strokeLineJoin: 'bevil'
    }, false);
    editor.canvas.add(myStar);
  }
  function starPolygonPoints(spikeCount, outerRadius, innerRadius) {
    var rot = Math.PI / 2 * 3;
    var cx = outerRadius;
    var cy = outerRadius;
    var sweep = Math.PI / spikeCount;
    var points = [];
    var angle = 0;

    for (var i = 0; i < spikeCount; i++) {
      var x = cx + Math.cos(angle) * outerRadius;
      var y = cy + Math.sin(angle) * outerRadius;
      points.push({ x: x, y: y });
      angle += sweep;

      x = cx + Math.cos(angle) * innerRadius;
      y = cy + Math.sin(angle) * innerRadius;
      points.push({ x: x, y: y });
      angle += sweep
    }
    return (points);
  }




  //   var canvas = new fabric.Canvas('...');
  // canvas.on('mouse:down', function(options) {
  //   console.log(options.e.clientX, options.e.clientY);
  // });






















  const onAddText = () => {
    console.log(1)
    // editor.addText(text);
    // editor.canvas.add(new fabric.IText(text, { 
    //   fontFamily: 'arial black',
    //   left: 100, 
    //   top: 100 ,
    // }));
    // setText("");

    const texts = new fabric.IText(text, {
      "left": 290,
      "top": 100,
      fontFamily: 'helvetica',
      editingBorderColor: 'white',
      angle: 0,
      fill: 'black',
      borderColor: 'red',
      scaleX: 0.5,
      scaleY: 0.5,
      "width": 53.38,
      "height": 45.2,
      fontWeight: '',
      hasRotatingPoint: true
    });
    editor.canvas.add(texts);
    editor.canvas.setActiveObject(texts);
    // editor.canvas.selectItemAfterAdded(texts);
    setText("                  ");
  }

















  const onDeleteAll = () => {
    var canvas = new fabric.Canvas('canvas');
    editor.deleteAll();
    // canvas.set("backgroundImage", '');
    // canvas.renderAll();
    // onReady(canvas);
    // setText("");
    // $("#upld-img").show();
    window.location.reload()
  };
  const onDeleteSelected = () => {
    editor.deleteSelected();
  };









  function onDeleteSingle() {
    // console.log(editor.canvas.getActiveObject().get('type'))

    // if (editor.canvas.getActiveObject().get('type') == "image") {
    //   alert("can not delete image");
    //   return false
    // }
    editor.canvas.remove(editor.canvas.getActiveObject());
  }




  // useEffect(()=>{
  // console.log(text)
  // },[text]);






  function changeTextFont(val) {
    // alert($("#text-fonts").val());
    var textFonts = $("#text-fonts").val();
    $("#text-fonts").css('font-family', textFonts)
    setFont(textFonts);

    editor.canvas.getActiveObject().set("fontFamily", textFonts);
    editor.canvas.renderAll();

  }








  function changeColor() {
    // var canvas = new fabric.Canvas('canvas');
    var cValue = color;
    console.log(cValue, editor.canvas.getActiveObject())


    // This is the imp line
    // canvas.getActiveObject()._objects[0].set("fill",cValue);

    editor.canvas.getActiveObject().set("fill", cValue);
    editor.canvas.renderAll();
    //  onReady(canvas);
  }












  function changeColorBackGround() {
    editor.canvas.backgroundColor = color;
    editor.canvas.renderAll();
  }














  function imgChng(e) {
    var canvas = new fabric.Canvas('canvas');
    var file = e.target.files[0];
    console.log(file)
    var reader = new FileReader();
    reader.onload = function (f) {
      var data = f.target.result;

      fabric.Image.fromURL(data, function (img) {
        console.log("editor canvas: ", canvas);
        canvas.set("backgroundImage", img);
        canvas.renderAll();
        onReady(canvas);
      });
    };
    reader.readAsDataURL(file);
  }





















  function imgUploadFromLocal(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (f) {
      var data = f.target.result;
      fabric.Image.fromURL(data, function (img) {
        var oImg = img.set({
          left: 100,
          top: 60,
          transformMatrix: [1, 0, 0, 1, 0, 0]
        });
        // img.scaleToWidth(200);
        img.scaleToHeight(250);
        img.scaleToWidth(500);
        editor.canvas.add(oImg).renderAll();
        var a = editor.canvas.setActiveObject(oImg);
        var dataURL = editor.canvas.toDataURL({ format: 'png', quality: 0.8 });
      });
    };
    reader.readAsDataURL(file);
  }











  function imgClick(e) {
    const el = e.target;
    console.log(el)
    fabric.loadSVGFromURL(el.src, function (objects, options) {

      var loadedObjects = fabric.util.groupSVGElements(objects, options);
      console.log(loadedObjects)

      loadedObjects.set({
        width: 250,
        height: 250
      });

      editor.canvas.add(loadedObjects);
      editor.canvas.renderAll();

    })
  }
















  ///*************  MOST IMP FUNCTION */
  function save() {

    var imageBase64 = editor.canvas.toDataURL()
    var layoutOfImage = JSON.stringify(editor.canvas);

    //api call here
    var fd = new FormData;
    fd.append("name", "base64");
    fd.append("image", imageBase64);
    fd.append("image_layout", layoutOfImage);
    fd.append("description", "hello from app");

    // return false;
    return axios.put(`${baseUrl}/apis/image-upload/${param.id}/`, fd, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `token ` + localStorage.getItem('auth_token')
      }
    }).then((res) => {
      console.log(res)
      if (res.data.status === true) {
        alert("successfully image updated")
        //    localStorage.setItem("editor", JSON.stringify(editor.canvas))
        // navigate('/medialibrary')
        // navigate("/new-editor-edit")
      } else {
        alert("something went wrong")
      }
    })
      .catch((err) => {
        console.log(err);
      });




  }
































  const [currentObject2, setCurrentObject] = useState('')
  const [currentImage2, setCurrentImage] = useState('')
  const [selectionRect2, setSelectionRect] = useState({})


  //===========================================================================================================

  //11111111111111111111111111111111
  function markCropArea(e) {
    if (editor.canvas.getActiveObject() == null || editor.canvas.getActiveObject().get('type') != "image") {
      alert("please chose image")
      return false
    }
    let currentImage;
    console.log("marked part-1")
    setCurrentObject(editor.canvas.getActiveObject())
    var currentObject = editor.canvas.getActiveObject();

    var imagesrc = currentObject._originalElement.currentSrc;
    console.log(currentImage, imagesrc)
    // return false;
    currentImage = currentObject;
    setCurrentImage(currentObject)

    //for crop arae
    var selectionRect = new fabric.Rect({
      fill: "rgba(0,0,0,0.3)",
      originX: "left",
      originY: "top",
      stroke: "black",
      opacity: 1,
      width: currentImage.width,
      height: currentImage.height,
      hasRotatingPoint: false,
      transparentCorners: false,
      cornerColor: "white",
      cornerStrokeColor: "black",
      borderColor: "black",
      cornerSize: 12,
      padding: 0,
      cornerStyle: "circle",
      borderDashArray: [5, 5],
      borderScaleFactor: 1.3,
    });

    console.log("marked part-2")


    selectionRect.scaleToWidth(300);
    editor.canvas.centerObject(selectionRect);
    editor.canvas.add(selectionRect);
    editor.canvas.setActiveObject(selectionRect);
    editor.canvas.renderAll();
    // document.querySelector("#crop").style.display = "block";
    $("#crop").show();
    console.log("mark clicked part 3")
    setSelectionRect(selectionRect)
  }















  function finalCrop(e) {
    console.log('crop----abcd')
    console.log(currentObject2, currentImage2, selectionRect2)

    document.querySelector("button#crop").style.display = "none";

    // create mask rectabgle for crop
    let rect = new fabric.Rect({
      left: selectionRect2.left,
      top: selectionRect2.top,
      width: selectionRect2.getScaledWidth(),
      height: selectionRect2.getScaledHeight(),
      absolutePositioned: true,
    });


    // add to the current image clicpPath property
    currentImage2.clipPath = rect;


    // remove the mask layer
    editor.canvas.remove(selectionRect2);


    var cropped = new Image();
    cropped.src = editor.canvas.toDataURL({
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height
    });
    cropped.onload = function () {

      // editor.canvas.clear();
      var image = new fabric.Image(cropped);
      image.left = rect.left;
      image.top = rect.top;
      image.setCoords();
      editor.canvas.add(image);
      editor.canvas.renderAll();
    };

    var imagesrc = currentObject2._originalElement.currentSrc;
    console.log(currentImage2, imagesrc)
    editor.canvas.remove(currentImage2);
    e.stopPropagation();
    e.preventDefault();
  }










  // ALL LAYER CHANGES

  function layerFun1() {
    console.log("layer change to bottom")
    console.log(editor.canvas.getActiveObject())
    var selectedObject = editor.canvas.getActiveObject();
    editor.canvas.sendToBack(selectedObject);


    // $("#canvas").trigger('click')
    // editor.canvas.renderAll();
  }



  function layerFun2() {
    console.log("layer change to bottom")
    console.log(editor.canvas.getActiveObject())
    var selectedObject = editor.canvas.getActiveObject();
    editor.canvas.sendBackwards(selectedObject)

  }



  function layerFun3() {
    console.log("layer change to bottom")
    console.log(editor.canvas.getActiveObject())
    var selectedObject = editor.canvas.getActiveObject();
    editor.canvas.bringToFront(selectedObject)
  }



  function layerFun4() {
    console.log("layer change to bottom")
    console.log(editor.canvas.getActiveObject())
    var selectedObject = editor.canvas.getActiveObject();
    editor.canvas.bringForward(selectedObject)
  }






  //=========================Blur AND DISTORTION PART START ================================== -------- 
  function blurInput() {
    console.log("blur input", editor.canvas.getActiveObject().get('type'));
    if (editor.canvas.getActiveObject() == null || editor.canvas.getActiveObject().get('type') != "image") {
      alert("please chose image")
      return false
    }

    var val = parseFloat($("#blur-value").val(), 10)
    console.log(val);


    var obj = editor.canvas.getActiveObject();

    var filter = new fabric.Image.filters.Blur({
      blur: val
    });
    while (obj.filters.length > 0) {
      obj.filters.pop();
    }
    obj.filters.push(filter);
    obj.applyFilters();
    editor.canvas.renderAll();
    console.log(obj)
    return false

    // var obj = editor.canvas.getActiveObject();
    // obj.filters[11]['blur'] = val;
    // var timeStart = +new Date();
    // obj.applyFilters();
    // var timeEnd = +new Date();
    // var dimString = editor.canvas.getActiveObject().width + ' x ' +
    //   editor.canvas.getActiveObject().height;
    // $('bench').innerHTML = dimString + 'px ' +
    //   parseFloat(timeEnd-timeStart) + 'ms';
    // editor.canvas.renderAll();

    // editor.canvas.renderAll();
  }









  const [disStatus, setDisStatus] = useState("1");
  const [a, seta] = useState(1);
  const [b, setb] = useState(0);
  const [c, setc] = useState(0);
  const [d, setd] = useState(1);
  const [tx, settx] = useState(0);
  const [ty, setty] = useState(0);

  function TwoDdistortion() {
    if (editor.canvas.getActiveObject() == null || editor.canvas.getActiveObject().get('type') != "image") {
      alert("please chose image")
      return false
    }

    // var a=$("#a").val();
    // var b=$("#b").val();
    // var c=$("#c").val();
    // var d=$("#d").val();
    // var tx=$("#tx").val();
    // var ty=$("#ty").val();
    console.log(a, b, c, d, tx, ty)

    var obj = editor.canvas.getActiveObject();
    // get the current transform matrix, from object properties.
    var currentT = obj.calcTransformMatrix();
    // get the transformMatrix array
    // var transformMatrix = obj.transformMatrix;
    if (disStatus === "1") {
      setDisStatus("0")
      var transformMatrix = [1, 0, 0, 1, 0, 0];
    } else {
      var transformMatrix = obj.transformMatrix;
    }
    // multiply the matrices together to get the combined transform
    var mT = fabric.util.multiplyTransformMatrices(currentT, transformMatrix);



    console.log(obj)
    console.log(currentT)
    console.log(transformMatrix)
    console.log(mT)


    // Unfold the matrix in a combination of scaleX, scaleY, skewX, skewY...
    // [0.14641288433382138, 0, 0, 0.14641288433382138, 200, 116.22254758418741]--1
    var options = fabric.util.qrDecompose(mT);
    var newCenter = { x: options.translateX, y: options.translateY };
    // reset transformMatrix to identity and resets flips since negative scale
    // resulting from decompose, will automatically set them.
    obj.transformMatrix = [a, b, c, d, tx, ty];
    obj.flipX = false;
    obj.flipY = false;
    obj.set(options);
    // position the object in the center given from translateX and translateY
    obj.setPositionByOrigin(newCenter, 'center', 'center');
    editor.canvas.renderAll();
    if (disStatus === "1") {
      setDisStatus("0")
      console.log(1)
      //   $("#dis-btn").trigger('click');
    }
    $("#dis-btn").trigger('click');
    seta(1)
    setb(0)
    setc(0)
    setd(1)
    settx(0)
    setty(0)
  }



  //========================= BLUR  AND DISTORTION PART END =======================//








  // --======================== edit tools show and hide start --===================== 
  function showHideEditTools() {

    var div_sec = $("#div-1").is(":visible")
    // console.log(div_sec);

    if ($(".show-edit-area").is(":visible") === true) {
      console.log($(".show-edit-area :visible").length)
      if ($(".show-edit-area :visible").length === 13) {
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');

      }
    }
    else {

      if ($(".show-edit-area").is(":visible") === false) {
        // $("#div-1").show()
        $('#leftSide').removeClass('col-lg-1');
        $('#leftSide').addClass('col-lg-2');

        $('#rightSide').removeClass('col-lg-3');
        $('#rightSide').addClass('col-lg-2');

      } else {
        // $("#div-1").hide()
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }

    console.log($(".show-edit-area").is(":visible"));

    if (div_sec === false) {
      $("#div-1").show()
    } else {
      $("#div-1").hide()
    }
  }




  function showHideEditTools2() {
    var div_sec = $("#div-2").is(":visible")
    // console.log(div_sec);
    if ($(".show-edit-area").is(":visible") === true) {
      console.log($(".show-edit-area :visible").length)
      if ($(".show-edit-area :visible").length === 9) {
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }
    else {

      if ($(".show-edit-area").is(":visible") === false) {
        // $("#div-1").show()
        $('#leftSide').removeClass('col-lg-1');
        $('#leftSide').addClass('col-lg-2');

        $('#rightSide').removeClass('col-lg-3');
        $('#rightSide').addClass('col-lg-2');
      } else {
        // $("#div-1").hide()
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }
    console.log($(".show-edit-area").is(":visible"));

    if (div_sec === false) {
      $("#div-2").show()
    } else {
      $("#div-2").hide()
    }
  }




  function showHideEditTools3() {
    var div_sec = $("#div-3").is(":visible")
    // console.log(div_sec);

    if ($(".show-edit-area").is(":visible") === true) {
      console.log($(".show-edit-area :visible").length)
      if ($(".show-edit-area :visible").length === 7) {
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }
    else {

      if ($(".show-edit-area").is(":visible") === false) {
        // $("#div-1").show()
        $('#leftSide').removeClass('col-lg-1');
        $('#leftSide').addClass('col-lg-2');

        $('#rightSide').removeClass('col-lg-3');
        $('#rightSide').addClass('col-lg-2');
      } else {
        // $("#div-1").hide()
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }

    if (div_sec === false) {
      $("#div-3").show()
    } else {
      $("#div-3").hide()
    }
  }







  function showHideEditTools4() {
    var div_sec = $("#div-4").is(":visible")
    // console.log(div_sec);

    if ($(".show-edit-area").is(":visible") === true) {
      console.log($(".show-edit-area :visible").length)
      if ($(".show-edit-area :visible").length === 17) {
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }

    }
    else {

      if ($(".show-edit-area").is(":visible") === false) {
        // $("#div-1").show()
        $('#leftSide').removeClass('col-lg-1');
        $('#leftSide').addClass('col-lg-2');

        $('#rightSide').removeClass('col-lg-3');
        $('#rightSide').addClass('col-lg-2');
      } else {
        // $("#div-1").hide()
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }


    if (div_sec === false) {
      $("#div-4").show()
    } else {
      $("#div-4").hide()
    }
  }







  function showHideEditTools5() {
    var div_sec = $("#div-5").is(":visible")
    // console.log(div_sec);

    if ($(".show-edit-area").is(":visible") === true) {
      console.log($(".show-edit-area :visible").length)
      if ($(".show-edit-area :visible").length === 9) {
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }
    else {

      if ($(".show-edit-area").is(":visible") === false) {
        // $("#div-1").show()
        $('#leftSide').removeClass('col-lg-1');
        $('#leftSide').addClass('col-lg-2');

        $('#rightSide').removeClass('col-lg-3');
        $('#rightSide').addClass('col-lg-2');
      } else {
        // $("#div-1").hide()
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }


    if (div_sec === false) {
      $("#div-5").show()
    } else {
      $("#div-5").hide()
    }
  }






  function showHideEditTools6() {
    var div_sec = $("#div-6").is(":visible")
    // console.log(div_sec);

    if ($(".show-edit-area").is(":visible") === true) {
      console.log($(".show-edit-area :visible").length)
      if ($(".show-edit-area :visible").length === 5) {
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }
    else {

      if ($(".show-edit-area").is(":visible") === false) {
        // $("#div-1").show()
        $('#leftSide').removeClass('col-lg-1');
        $('#leftSide').addClass('col-lg-2');

        $('#rightSide').removeClass('col-lg-3');
        $('#rightSide').addClass('col-lg-2');
      } else {
        // $("#div-1").hide()
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }

    if (div_sec === false) {
      $("#div-6").show()
    } else {
      $("#div-6").hide()
    }
  }






  function showHideEditTools7() {
    var div_sec = $("#div-7").is(":visible")
    // console.log(div_sec);

    if ($(".show-edit-area").is(":visible") === true) {
      console.log($(".show-edit-area :visible").length)
      if ($(".show-edit-area :visible").length === 96) {
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }
    else {

      if ($(".show-edit-area").is(":visible") === false) {
        // $("#div-1").show()
        $('#leftSide').removeClass('col-lg-1');
        $('#leftSide').addClass('col-lg-2');

        $('#rightSide').removeClass('col-lg-3');
        $('#rightSide').addClass('col-lg-2');
      } else {
        // $("#div-1").hide()
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }

    if (div_sec === false) {
      $("#div-7").show()
    } else {
      $("#div-7").hide()
    }
  }





  function showHideEditTools8() {
    var div_sec = $("#div-8").is(":visible")
    // console.log(div_sec);

    if ($(".show-edit-area").is(":visible") === true) {
      console.log($(".show-edit-area :visible").length)
      if ($(".show-edit-area :visible").length === 4) {
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }
    else {

      if ($(".show-edit-area").is(":visible") === false) {
        // $("#div-1").show()
        $('#leftSide').removeClass('col-lg-1');
        $('#leftSide').addClass('col-lg-2');

        $('#rightSide').removeClass('col-lg-3');
        $('#rightSide').addClass('col-lg-2');
      } else {
        // $("#div-1").hide()
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }

    if (div_sec === false) {
      $("#div-8").show()
    } else {
      $("#div-8").hide()
    }
  }







  function showHideEditTools9() {
    var div_sec = $("#div-9").is(":visible")
    // console.log(div_sec);

    if ($(".show-edit-area").is(":visible") === true) {
      console.log($(".show-edit-area :visible").length)
      if ($(".show-edit-area :visible").length === 11) {
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }
    else {

      if ($(".show-edit-area").is(":visible") === false) {
        // $("#div-1").show()
        $('#leftSide').removeClass('col-lg-1');
        $('#leftSide').addClass('col-lg-2');

        $('#rightSide').removeClass('col-lg-3');
        $('#rightSide').addClass('col-lg-2');
      } else {
        // $("#div-1").hide()
        $('#leftSide').removeClass('col-lg-2');
        $('#leftSide').addClass('col-lg-1');

        $('#rightSide').removeClass('col-lg-2');
        $('#rightSide').addClass('col-lg-3');
      }
    }

    if (div_sec === false) {
      $("#div-9").show()
    } else {
      $("#div-9").hide()
    }
  }













  return (
    <AppBase>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="closed-modal-btn" onClick={closeModal}>x</button>
        <br></br>
        <button onClick={abc} id="jeet" className="modal-edit-btns">Are you sure want to edit</button>
      </Modal>


      <div className="App" id="main-div" style={{ display: 'none' }}>
        {/* <h1 className="edit-heading">Edit </h1> */}

        {/* style={{display:"none"}} */}
        {/* <div className="upld-img" id="upld-img" >
                    <h1>Upload Image</h1>
                    <form id="uploadImg" runat="server">
                        <input type="file" id="uploadedImg" onChange={imgChng2} />
                    </form>
                </div> */}


        <div id="main-div" >

          <div className="row mx-auto">
            <div id="leftSide" className="col-lg-1 col-md-2 col-12 my-2 left-edit-cnt">
              <div className="left-sidebar-content">
                <div className="all-edit-tools">


                  <div className="edit-tools">
                    <div className="mb-2">
                      <button onClick={showHideEditTools} className="edit-tools-btns">
                        <FaShapes />
                        <p>Shapes</p>
                      </button>
                      <div className="show-edit-area" id="div-1" style={{ display: 'none' }}>
                        <div className="edit-tool-area-shape">
                          <button onClick={onAddCircle} className='edit-btns circle-shape'></button>
                          <button onClick={onAddRectangle} className='edit-btns rect-shape'></button>
                          <button onClick={onAddEcipls} className='edit-btns ellip-shape'></button>
                          <button onClick={onAddTriangle} className='edit-btns triangle-shape'><BsTriangle /></button>
                          <button onClick={poly} className='edit-btns polygon-shape'><BiPolygon /></button>
                          <button onClick={star} className='edit-btns star-shape'><BsStar /></button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button onClick={showHideEditTools2} className="edit-tools-btns">
                        <BsFillTrashFill />
                        <p>Delete</p>
                      </button>
                      <div className="show-edit-area" id="div-2" style={{ display: 'none' }}>
                        <div className="edit-tool-area">
                          <button onClick={onDeleteAll} className='edit-btns'><BsTrashFill /> <p>Delete All</p></button>
                          <button onClick={onDeleteSingle} className='edit-btns'> <BsTrashFill /> <p>Delete</p> </button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button onClick={showHideEditTools8} className="edit-tools-btns">
                        <FaCloudUploadAlt />
                        <p>Upload</p>
                      </button>
                      <div className="show-edit-area" id="div-8" style={{ display: 'none' }}>
                        <div className="" id="upld-img">
                          <h1>Upload More Image</h1>
                          <form id="uploadImg" runat="server">
                            <input type="file" className="upld-file" id="uploadedImg" onChange={imgUploadFromLocal} />
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button onClick={showHideEditTools3} className="edit-tools-btns">
                        <BiCrop />
                        <p>Crop</p>
                      </button>
                      <div className="show-edit-area" id="div-3" style={{ display: 'none' }}>
                        <div className="edit-tool-area">
                          <button id="crop" onClick={finalCrop} className='edit-btns' style={{ display: 'none' }}><ImCrop /> <p>Fixed Crop</p></button>
                          <button id="startCrop" onClick={markCropArea} className='edit-btns'><RiCrop2Fill /> <p>Free Crop</p></button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button onClick={showHideEditTools4} className="edit-tools-btns">
                        <FiLayers />
                        <p>Layers</p>
                      </button>
                      <div className="show-edit-area" id="div-4" style={{ display: 'none' }}>
                        <div className="edit-tool-area">
                          {/* <button onClick={cropFun}>take img</button> */}
                          {/* <img src={logo} /> */}
                          {/* <button style={{display:'none'}}></button> */}
                          <button onClick={layerFun1} className='edit-btns'><SiOpenlayers /><p>Bottom Layer</p></button>
                          <button onClick={layerFun2} className='edit-btns'><BsLayersHalf /><p>One Layer Bellow</p></button>
                          <button onClick={layerFun3} className='edit-btns'><SiOpenlayers /><p>Top Layer</p></button>
                          <button onClick={layerFun4} className='edit-btns'><BsLayersHalf /><p>One Layer Top</p></button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button onClick={showHideEditTools5} className="edit-tools-btns">
                        <BsBack />
                        <p>Background</p>
                      </button>
                      <div className="show-edit-area" id="div-5" style={{ display: 'none' }}>
                        <div className="bg-clr-change">
                          <h4>Change Background color</h4>
                          <input type="color" value={color} onChange={(e) => {
                            setColor(e.target.value);
                            changeColorBackGround()
                          }} />
                        </div>
                        <div className="bg-clr-change">
                          {/* <ColorPicker width={456} height={228} color={color} onChange={changeColor} hideHSV dark /> */}
                          {/* <input type="color" onChange={changeColor} /> */}
                          <h4>Change target component color</h4>
                          <input type="color" value={color} onChange={(e) => {
                            setColor(e.target.value);
                            changeColor()
                          }} />
                        </div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button onClick={showHideEditTools6} className="edit-tools-btns">
                        <ImTextHeight />
                        <p>Text</p>
                      </button>
                      <div className="show-edit-area add-text" id="div-6" style={{ display: 'none' }}>
                        <fieldset>
                          {/* <input
                            name={`text`}
                            type={`text`}
                            placeholder="Add text..."
                            value={text}
                            onChange={(event) => setText(event.target.value)}
                          /> */}
                          <button onClick={onAddText}>Add Text</button>
                        </fieldset>
                        <div className="font-change">
                          <h4>Change Font</h4>
                          <select id="text-fonts" onChange={changeTextFont}>
                            <option value={'sans-sarif'} style={{ fontFamily: 'sans-sarif' }} selected >sans-sarif </option>
                            <option value={'Combo'} style={{ fontFamily: 'Combo' }}  >Combo </option>
                            <option value={'Qwitcher Grypen'} style={{ fontFamily: 'Qwitcher Grypen' }}>Qwitcher Grypen </option>
                            <option value={'Montserrat'} style={{ fontFamily: 'Montserrat', }}>Montserrat </option>
                            <option value={'Raleway'} style={{ fontFamily: 'Raleway' }}>Raleway </option>
                            <option value={'Antonio'} style={{ fontFamily: 'Antonio' }}>Antonio </option>
                            <option value={'Lobster'} style={{ fontFamily: 'Lobster' }}>Lobster </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button onClick={showHideEditTools9} className="edit-tools-btns">
                        <FaFilter />
                        <p>Filters</p>
                      </button>
                      <div className="show-edit-area add-text" id="div-9" style={{ display: 'none' }}>
                        <div className="blur-value-change">
                          <h4>Blur Value:</h4>
                          {/* <label>Blur Value: */}
                          {/* <input onInput={blurInput}
                            type="number" id="blur-value"
                            min="0" max="1" defaultValue="0" /> */}
                          <select onChange={blurInput} id="blur-value">
                            <option value={0}>0</option>
                            <option value={0.1}>0.1</option>
                            <option value={0.2}>0.2</option>
                            <option value={0.3}>0.3</option>
                            <option value={0.4}>0.4</option>
                            <option value={0.5}>0.5</option>
                            <option value={0.6}>0.6</option>
                            <option value={0.7}>0.7</option>
                            <option value={0.8}>0.8</option>
                            <option value={0.9}>0.9</option>
                            <option value={1}>1</option>
                          </select>
                          {/* </label> */}
                        </div>
                        <div className="distortion-value-change" >
                          <h4>Distortion</h4>
                          <input type="number"
                            onChange={(e) => {
                              seta(e.target.value)
                            }}
                            value={a} step="0.02" id="a" />
                          <input type="number"
                            onChange={(e) => {
                              setc(e.target.value)
                            }}
                            value={c} step="0.02" id="c" />
                          <input type="number"
                            onChange={(e) => {
                              setd(e.target.value)
                            }}
                            value={d} step="0.02" id="d" />
                          <input type="number"
                            onChange={(e) => {
                              settx(e.target.value)
                            }}
                            value={tx} step="1" id="tx" />
                          <input type="number"
                            onChange={(e) => {
                              setty(e.target.value)
                            }}
                            value={ty} step="1" id="ty" />
                          <button onClick={TwoDdistortion} id="dis-btn">Distortion</button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button onClick={showHideEditTools7} className="edit-tools-btns">
                        <TbSticker />
                        <p>Stickers</p>
                      </button>
                      <div className="show-edit-area stickers-tools" id="div-7" style={{ display: 'none' }}>
                        {/* <div className="stickers-tools"> */}
                        {/* <div style={{ width: '100px' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/1.svg" /></div> */}
                        {/* <div style={{ width: '100px' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/2.svg" /></div> */}
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/3.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/4.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/5.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/6.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/7.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/8.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/9.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/10.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/11.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/12.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/13.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/14.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/15.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/16.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/17.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/18.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/19.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/20.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/21.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/22.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/23.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/24.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/25.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/26.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/27.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/28.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/29.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/30.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/31.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/32.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/33.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/34.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/35.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/36.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/37.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/38.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/39.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/40.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/41.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/42.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/43.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/44.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/45.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/46.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/47.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/48.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/49.svg" /></div>
                        <div style={{ width: '100%', margin: 'auto' }} onClick={imgClick}> <img src="http://fabricjs.com/assets/50.svg" /></div>
                        {/* </div> */}
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>

            <div className="col-md-8 col-12 my-2 canvas-area-divs">
              <div className="change-opts">
                <h4>Photo Editor & Template Designer</h4>
              </div>


              <div className="canvas-div">
                {/* <FabricJSCanvas className="sample-canvas" onReady={_onReady} /> */}
                <canvas id="canvas" width="1000" height="400"></canvas>
              </div>
              {/* <button className="save-btns mt-3 mt-2" onClick={save}> Edit</button>
              <hr></hr> */}
              {/* <canvas id="c" width="1000" height="500"></canvas> */}


              <div className="footer">
                <button className="save-btns" onClick={save}><AiOutlineDownload /> Edit</button>
              </div>
            </div>

            <div id="rightSide" className="col-lg-3 col-md-2 col-12 my-2 add-sec"></div>
          </div>

          {/* <div className="canvas-div">
            <canvas id="canvas" width="1000" height="500"></canvas>
          </div>
          <button onClick={save}> Edit</button> */}



        </div>





      </div>
    </AppBase>

  )
}

export default NewEditorEdit