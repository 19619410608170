import React from "react";
import BM from "../../Assets/BM.png";
const BannerSecMobileImage = () => {
  return (
    <>
      <div className="BannerSecMobileImage">
        <img src={BM} alt="" style={{ width: "100%" }} />
      </div>
    </>
  );
};

export default BannerSecMobileImage;
