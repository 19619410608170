import React from "react";
import OpenImage from "../Componrnts/EditPage/OpenImage";
import Header from "../Componrnts/HomePage/Header";
import EditWorkspace from "./EditWorkspace";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';
import { useEffect } from "react";
import AppBase from "../Componrnts/AppBase";



export const EditPage = (props) => {
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[]);

  function imgEditFun(){
    // ReactGA.event({
    //   category: 'Social',
    //   action: 'Rated an App',
    //   value: 3
    // });

    // navigate("/new-editor")
  }
  return (
    <AppBase {...props}>
      {/* <OpenImage /> */}
      <div className="mainsection" style={{ margin: "10%" }}>
          <div className="uploadvideo-rightsec" style={{ margin: "0%" }}>
            <div className="uploadvideo-innersection">
              <h1>Editing Made Simple</h1>
              <p>
                Create Videos with a Single Click. Add Subtitles ,transitions
                audio and more <br /> Try it now , no Account required
              </p>
              <div>
                <input
                  type="file"
                  className="file-input"
                  accept="image/*"
                  // onChange={onImageChange}
                  hidden
                />
              </div>
              <button
                className="choose-img"
                onClick={() => props.history.push("/new-editor")}
                // onClick={imgEditFun}
              >
               Edit image
              </button>
              <button
                className="choose-img mx-2"
                onClick={() => props.history.push("/video-editor")}
              >
                Edit video
              </button>
            </div>
          </div>
          <img src="foto-image" alt="" />
          <div className="uploadvideo-leftsec">
            {/* <img src={ImageH} alt="" style={{ width: "100%" }} /> */}
          </div>
        </div>
    </AppBase>
  );
};
