import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import ReactCrop from 'react-image-crop'
import $ from 'jquery';
import AboutUs1 from "../Assets/AboutUs1.png"
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    TwitterIcon, 
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux'

const customStyles = {
    content: {
        top: '100%',
        left: '100%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function ModalComponent({ data, index_id }) {

    const baseUrl = useSelector(state => state.TextSlice.baseurl);
    const [showModal, setShowModal] = useState(false);
    const { selectedObjects, editor, onReady } = useFabricJSEditor();

    useEffect(() => {
        // load(index_id);
        console.log(data.image)
    }, [])






















    //========================= CROP PART STRAT =======================//

    const [currentObject22, setCurrentObject2] = useState('')
    const [currentImage22, setCurrentImage2] = useState('')
    const [selectionRect22, setSelectionRect2] = useState({})


    //===========================================================================================================

    //11111111111111111111111111111111
    function markCropAreafb(e) {
        // console.log(editor.canvas.getActiveObject())
        if (editor.canvas.getActiveObject() == null || editor.canvas.getActiveObject().get('type') != "image") {
            alert("please chose image")
            return false
        }
        let currentImage;
        console.log("marked part-1")
        setCurrentObject2(editor.canvas.getActiveObject())
        var currentObject = editor.canvas.getActiveObject();

        var imagesrc = currentObject._originalElement.currentSrc;
        console.log(currentImage, imagesrc)
        // return false;
        currentImage = currentObject;
        setCurrentImage2(currentObject)
        console.log(currentImage.width, currentImage.height)

        //for crop arae
        var selectionRect = new fabric.Rect({
            // fill: "rgba(0,0,0,0.3)",
            // originX: "left",
            // originY: "top",
            // stroke: "black",
            // opacity: 1,
            // width: 700,
            // height: 500,
            // hasRotatingPoint: false,
            // transparentCorners: false,
            // cornerColor: "white",
            // cornerStrokeColor: "black",
            // borderColor: "black",
            // cornerSize: 12,
            // padding: 0,
            // cornerStyle: "circle",
            // borderDashArray: [5, 5],
            // borderScaleFactor: 1.3,
            width: 200,
            height: 100,
            fill: '',
            stroke: 'green',
            strokeWidth: 3
        });

        console.log("marked part-2")
        selectionRect.hasControls = false;


        selectionRect.scaleToWidth(300);
        editor.canvas.centerObject(selectionRect);
        editor.canvas.add(selectionRect);
        editor.canvas.setActiveObject(selectionRect);
        editor.canvas.renderAll();
        // document.querySelector("#crop").style.display = "block";
        $("#crop").show();
        $("#insta").hide();
        console.log("mark clicked part 3")
        setSelectionRect2(selectionRect)
    }






















    function markCropAreaInsta() {
        // console.log(editor.canvas.getActiveObject())
        if (editor.canvas.getActiveObject() == null || editor.canvas.getActiveObject().get('type') != "image") {
            alert("please chose image")
            return false
        }
        let currentImage;
        console.log("marked part-1")
        setCurrentObject2(editor.canvas.getActiveObject())
        var currentObject = editor.canvas.getActiveObject();

        var imagesrc = currentObject._originalElement.currentSrc;
        console.log(currentImage, imagesrc)
        // return false;
        currentImage = currentObject;
        setCurrentImage2(currentObject)
        console.log(currentImage.width, currentImage.height)

        //for crop arae
        var selectionRect = new fabric.Rect({
            // fill: "rgba(0,0,0,0.3)",
            // originX: "left",
            // originY: "top",
            // stroke: "black",
            // opacity: 1,
            // width: 700,
            // height: 500,
            // hasRotatingPoint: false,
            // transparentCorners: false,
            // cornerColor: "white",
            // cornerStrokeColor: "black",
            // borderColor: "black",
            // cornerSize: 12,
            // padding: 0,
            // cornerStyle: "circle",
            // borderDashArray: [5, 5],
            // borderScaleFactor: 1.3,
            width: 300,
            height: 200,
            fill: '',
            stroke: 'green',
            strokeWidth: 3
        });

        console.log("marked part-2")
        selectionRect.hasControls = false;


        selectionRect.scaleToWidth(300);
        editor.canvas.centerObject(selectionRect);
        editor.canvas.add(selectionRect);
        editor.canvas.setActiveObject(selectionRect);
        editor.canvas.renderAll();
        // document.querySelector("#crop").style.display = "block";
        $("#crop").show();
        $("#fb").hide();
        console.log("mark clicked part 3")
        setSelectionRect2(selectionRect)
    }













    const [fbShareUrl, setFbShareUrl] = useState('');


    function finalCrop(e) {
        console.log('crop----abcd')
        console.log(currentObject22, currentImage22, selectionRect22)

        document.querySelector("button#crop").style.display = "none";
        $("#fb").show();
        $("#insta").show();

        // create mask rectabgle for crop
        let rect = new fabric.Rect({
            left: selectionRect22.left,
            top: selectionRect22.top,
            width: selectionRect22.getScaledWidth(),
            height: selectionRect22.getScaledHeight(),
            absolutePositioned: true,
        });


        // add to the current image clicpPath property
        currentImage22.clipPath = rect;


        // remove the mask layer
        editor.canvas.remove(selectionRect22);


        var cropped = new Image();
        // cropped.crossOrigin = '*';  //<-- set here
        cropped.setAttribute('crossOrigin', '*');
        cropped.src = editor.canvas.toDataURL({
            left: rect.left,
            top: rect.top,
            width: rect.width,
            height: rect.height,
            allowTaint: true,
            foreignObjectRendering: true
        });
        cropped.onload = function () {

            // editor.canvas.clear();
            var image = new fabric.Image(cropped);
            image.left = rect.left;
            image.top = rect.top;
            image.setCoords();
            editor.canvas.add(image);
            editor.canvas.renderAll();

            var imageBase64 = image.toDataURL();


            //----------------api call-------------------//
            console.log(imageBase64)

            // $("#fb").hide();
            // $("#insta").hide();
            // $("#fb-icon").show();
            // $("#twitter-icon").show();
            // setFbShareUrl('https://freeditor-dev.s3.amazonaws.com/images/6da3da63-7f67-418c-bcc4-54b4e5cc4036.png')
       
            // return false

            //api call here
            var fd = new FormData;
            // fd.append("name", "base64");
            fd.append("image", imageBase64);

            // return false;
            return axios.post(`${baseUrl}/apis/image-upload/`, fd, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `token ` + localStorage.getItem('auth_token')
                }
            }).then((res) => {
                console.log(res)
                if (res.data.status === true) {
                    $("#fb").hide();
                    $("#insta").hide();
                    $("#fb-icon").show();
                    $("twitter-icon").show();
                    setFbShareUrl('https://freeditor-dev.s3.amazonaws.com/images/6da3da63-7f67-418c-bcc4-54b4e5cc4036.png')
                } else {
                    alert("something went wrong")
                }
            })
                .catch((err) => {
                    console.log(err);
                });
        }; //crop onload



        var imagesrc = currentObject22._originalElement.currentSrc;
        // console.log(currentImage22, imagesrc)
        editor.canvas.remove(currentImage22);
        e.stopPropagation();
        e.preventDefault();

        console.log()
    }


    //========================= CROP PART END =======================//












    const [crop, setCrop] = useState('')
    function croping(c) {
        console.log(c)
        // setCrop(c)
    }


















    return (
        <div key={index_id}>
            <button
                style={{ marginBottom: 10 }}
                onClick={() => {
                    setShowModal(true);

                    setTimeout(() => {
                        let canvas = new fabric.Canvas('c' + index_id);
                        // canvas.backgroundColor = 'white';
                        canvas.renderAll();
                        fabric.Image.fromURL(AboutUs1, function (img) {
                            // fabric.Image.fromURL(data.image, function (img) {
                            var oImg = img.set({
                                left: 100,
                                top: 40,
                            });
                            img.scaleToHeight(300);
                            img.scaleToWidth(500);
                            // img.hasControls = false;
                            // img.hasBorders = false;
                            canvas.add(oImg).renderAll();
                            // canvas.add(myImg);
                        });
                        // alert("i")
                        // markCropArea()
                        onReady(canvas);

                    }, 1000);





                }}
            >
                Share {index_id}
            </button>
            <Modal onHide={() => setShowModal(false)} show={showModal} style={customStyles}>
                {data.created_at}
                {/* <img src={data.image} style={{ width: '100%' }} /> */}
                {/* <form id="uploadImg" runat="server">
                    <input type="file" className="upld-file" id="uploadedImg" onChange={imgChng2} />
                </form> */}


                <canvas id={"c" + index_id} width="745" height="400"></canvas>



                {/* <ReactCrop crop={crop} minWidth={50} minHeight={50} onChange={(c) => {
                    croping(c)
                }}>
                    <img src={data.image} style={{ width: '150%' }} />
                </ReactCrop> */}


                <div>
                    <button id="fb" onClick={markCropAreafb}>fb</button>
                    <button id="insta" onClick={markCropAreaInsta}>Twitter</button>

                    <button id="crop"
                        onClick={finalCrop}
                        style={{ display: 'none' }}>Resize</button>

                    <FacebookShareButton url={fbShareUrl}>
                        <FacebookIcon size={32} round={true} id="fb-icon" style={{ display: 'none' }} />
                    </FacebookShareButton>

                    <TwitterShareButton  url={fbShareUrl}>
                        <TwitterIcon  size={32} round={true} id="twitter-icon" style={{ display: 'none' }}  />
                    </TwitterShareButton>

                </div>

            </Modal>
        </div>
    );
}

export default ModalComponent;
