import React, {useEffect} from "react";
import {useSelector} from "react-redux";

const SignUp = (props) => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    useEffect(() => {
        if (isLoggedIn) {
            props.history.push('/')
        }
    }, [isLoggedIn])
    return (
        <div className="card logincontainer">
            <div className="logininner">
                <div className="logininner1">
                    <div className="signinbutton">
                        <div className="signinbar"></div>
                        <button>Sign In</button>
                    </div>
                    <div className="signupbutton">
                        <div className="signinbar loginactive"></div>

                        <button className="loginactive">Sign Up</button>
                    </div>
                </div>
                <div className="logininner2">
                    <div
                        className="card loginlogocard"
                        style={{
                            width: "40%",
                            backgroundColor: "#0372BC",
                            color: "white",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            border:0
                        }}
                    >
                        LOGO.
                    </div>
                </div>
                <div className="logininner3 mt-5"></div>
            </div>
        </div>
    );
};

export default SignUp;
