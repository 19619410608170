import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import RouteContext from './RouteContext';

import HomePage from "../Pages/HomePage";
import SignUp from "../Pages/SignUp";
import {EditPage} from "../Pages/EditPage";
import EditWorkspace from "../Pages/EditWorkspace";
import EditWorkspaceVideo from "../Pages/EditWorkspaceVideo";
import MediaLibrary from "../Pages/MediaLibrary";
import DesignedImages from "../Pages/DesignedImages";
import AboutUs from "../Pages/AboutUs";
import ContactUs from "../Pages/ContactUs";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsCondition from "../Pages/TermsCondition";
import Faq from "../Pages/Faq";
import Company from "../Pages/Company";
import MyAllDesigns from "../Pages/MyAllDesigns";
import Editor from "../Componrnts/EditPage/Editor";
import NewEditorEdit from "../Componrnts/EditPage/NewEditorEdit";
import NoPage from "../Pages/NoPage";
import VideoEditor from "../Componrnts/VideoEditor";
import ShortCutKeys from "../utils/shortCutKeys";
import ShortCutsContext from "../ShortCutsContext";

const RoutesHome = (props) => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    return (
        <RouteContext.Provider value={props}>
            <Switch>
                <Route
                    exact
                    path={['/', '/home']}
                    render={(route) => <HomePage {...route} />}
                />
                <Route
                    exact
                    path={'/signup'}
                    render={(route) => <SignUp {...route} />}
                />
                <Route
                    exact
                    path={'/edit'}
                    render={(route) => <EditPage {...route} />}
                />
                <Route
                    exact
                    path={'/editworkspace'}
                    render={(route) => <EditWorkspace {...route} />}
                />

                <Route
                    exact
                    path={'/editworkspacevideo'}
                    render={(route) => <EditWorkspaceVideo {...route} />}
                />
                <Route
                    exact
                    path={'/medialibrary'}
                    render={(route) => <MediaLibrary {...route} />}
                />
                <Route
                    exact
                    path={'/designedimages'}
                    render={(route) => <DesignedImages {...route} />}
                />
                <Route
                    exact
                    path={'/aboutus'}
                    render={(route) => <AboutUs {...route} />}
                />
                <Route
                    exact
                    path={'/contact-us'}
                    render={(route) => <ContactUs {...route} />}
                />
                <Route
                    exact
                    path={'/privacy-policy'}
                    render={(route) => <PrivacyPolicy {...route} />}
                />
                <Route
                    exact
                    path={'/terms-conditions'}
                    render={(route) => <TermsCondition {...route} />}
                />


                <Route
                    exact
                    path={'/faq'}
                    render={(route) => <Faq {...route} />}
                />

                <Route
                    exact
                    path={'/company'}
                    render={(route) => <Company {...route} />}
                />
                <Route
                    exact
                    path={'/my-all-designs'}
                    render={(route) => <MyAllDesigns {...route} />}
                />
                <Route
                    exact
                    path={'/new-editor'}
                    render={(route) =>  <ShortCutKeys>
                        <ShortCutsContext.Consumer>{shortCutRef => <Editor {...route}  shortCutRef={shortCutRef}/>}</ShortCutsContext.Consumer>
                    </ShortCutKeys>}
                />
                <Route
                    exact
                    path={'/new-editor/:id'}
                    render={(route) => <Editor {...route} />}
                />
                <Route
                    exact
                    path={'/video-editor'}
                    render={(route) => <VideoEditor {...route} />}
                />

                <Route
                    exact
                    path={'*'}
                    render={(route) => <NoPage {...route} />}
                />
            </Switch>
        </RouteContext.Provider>
    );

}

const mapStateToProps = (state) => {
    return {
        user: null,
        loggedIn: null,
        userRoles: null
    };
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RoutesHome);
